import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addDuration, addSlideTime, updateTextData } from "../../actions/editorAction";

const Settings = (props) => {

    const dispatch = useDispatch();

    const imageData = useSelector(state => state.currentLayer.data.images_data);
    const videoDuration = useSelector(state => state.currentLayer.data.duration);
    const auth = useSelector(state => state.auth);

    const [memberships, setMemberships] = useState([]);
    const [duration, setDuration] = useState(videoDuration);
    const [slideTime, setSlideTime] = useState(1);
    const [opacity, setOpacity] = useState(1);
    const [selectedImage, setSelectedImage] = useState([]);

    const handleDuration = (e) => {
        let slideTime = e.target.value;

        if (memberships.includes('unlimited') || memberships.includes('unlimitedlite')) {
            if (slideTime <= 1800) {
                setDuration(e.target.value);
                dispatch(addDuration(e.target.value));
            }
        }
        else {
            if (slideTime <= 600) {
                setDuration(e.target.value);
                dispatch(addDuration(e.target.value));
            }
        }


    }

    const handleSlideTime = (e) => {
        setSlideTime(e.target.value);

        dispatch(addSlideTime(e.target.value))
    }
    const handleOpacity = (e) => {
        let value = e.target.value
        if (!value || value > 1) {
            value = 0.9
        }
        const newOpacity = parseFloat(value);
        setOpacity(newOpacity);

        dispatch(updateTextData('UPDATE_IMAGE_OPACITY', newOpacity));

        let data = {
            id: selectedImage[0].id,
            opacity: newOpacity
        };
        dispatch(updateTextData('UPDATE_TIMELINE_IMAGE_OPACITY', data));
    };

    useEffect(() => {
        if (imageData.length > 0) {
            let activeImage = imageData.filter(image => image.is_selected === true);

            setSelectedImage(activeImage);

            if (activeImage !== undefined && activeImage !== null && activeImage.length > 0) {
                setOpacity(activeImage[0].opacity);
            }
        }
    }, [imageData])

    useEffect(() => {
        // dispatch(addDuration(30));
        setDuration(duration);
    }, [])

    /* For Check Membership Levels */
    useEffect(() => {
        if (auth.user !== null) {

            let memLevels = auth.user.membership.split('__');
            setMemberships(memLevels);

        }
    }, [auth.user])

    return (
        <>
            <div className="editor-text-customization">
                <div className="editor-wrapper editor-text-wrapper">
                    <span className="label text-white col-12 p-0 mb-3" style={{ fontFamily: 'Poppins', fontWeight: 500 }}>Video Duration <small>(Sec)</small></span>
                    <div className="editor-font-family-size-wrapper align-content-center mt-3">
                        <div className="input-wrapper w-100">
                            <input id="f-size" type="number" onChange={(e) => handleDuration(e)} className="form-control editor-input-dark edit-input-height"
                                name="f-size" value={duration} min="1" />
                        </div>
                    </div>
                </div>

                {
                    props.type === 'campaign_four' ?
                        <div className="editor-wrapper editor-text-wrapper mt-3">
                            <span className="label text-white col-12 p-0 mb-3"
                                style={{ fontFamily: 'Poppins', fontWeight: 500 }}>Spin Count</span>
                            <div className="editor-font-family-size-wrapper align-content-center mt-3">
                                <div className="input-wrapper w-100">
                                    <input id="f-size" type="number" onChange={(e) => handleSlideTime(e)}
                                        className="form-control editor-input-dark edit-input-height"
                                        name="f-size" value={slideTime} min="1" max={90} />
                                </div>
                            </div>
                        </div>
                        : ''
                }


                {
                    props.type !== 'campaign_three' ?
                        <div className="editor-wrapper editor-text-wrapper mt-3">
                            <span className="label text-white col-12 p-0 mb-3" style={{ fontFamily: 'Poppins', fontWeight: 500 }}>Image/Video Opacity</span>
                            <div className="editor-font-family-size-wrapper align-content-center mt-3">
                                <div className="input-wrapper w-100">
                                    <input id="f-size" type="number" onChange={(e) => handleOpacity(e)} className="form-control editor-input-dark edit-input-height"
                                        name="f-size"
                                        min={0}
                                        max={1}
                                        value={selectedImage.length > 0 ? selectedImage[0].opacity : 1}
                                        step={0.1}
                                    />
                                </div>
                            </div>
                        </div>
                        : ''
                }

            </div>
        </>
    )
}

export default React.memo(Settings);