import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Header from "../Header";
import Footer from "../Footer";
import queryString from "query-string";
import "./css/meta-library.css";

import searchIcon from "../../images/search.svg";
import MetaVerseVideo from "./MetaVerseVideo";
import MetaVerseFootage from "./MetaVerseFootage";
import MetaVerseUpload from "./MetaVerseUpload";
import axios from "axios";
import { appName, baseURL, imageData } from "../../global/global";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { setAlert } from "../../actions/alert";
import Alert from "../Alert";
import { addCampaignData } from "../../actions/editorAction";
import swal from "sweetalert";
import MetaVerseCampaign from "./MetaVerseCampaign";
import PanaromicUpload from "./PamaromicUpload";
import randomstring from "randomstring";
import loadingGif from "../../images/loading.gif";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const CreateMetaVerse = ({ location }) => {


    const typeParam = queryString.parse(location.search).type;

    const history = useHistory();
    const auth = useSelector(state => state.auth);
    const brandName = useSelector(state => state.rebrand.data);
    const regularData = useSelector(state => state.script.regular_data);
    const dispatch = useDispatch();

    const [loader, setLoader] = useState(false);
    const [userLoader, setUserLoader] = useState(false);
    const [uploadLoader, setUploadLoader] = useState(false);
    const [pageLoader, setPageLoader] = useState(false);
    const [keyword, setKeyword] = useState('');
    const [type, setType] = useState(undefined);
    const [checkType, setCheckType] = useState('')
    const [percent, setPercent] = useState(0);

    const [panaImages, setPanaImages] = useState([]);
    const [videos360, setVideos360] = useState([]);
    const [videosUpload, setVideosUpload] = useState([]);
    const [videosFootage, setVideosFootage] = useState([]);
    const [campData, setCampData] = useState([]);
    const [page360, setPage360] = useState(1);
    const [pageFootage, setPageFootage] = useState(1);
    const [load360, setLoad360] = useState(false);
    const [loadFootage, setLoadFootage] = useState(false);

    const [errorMessage, setErrorMessage] = useState('');

    const handleFormSubmit = (e) => {
        e.preventDefault();
        setLoader(true);

        if (checkType === 'camera') {
            fetchVideos360();
        } else {
            fetch360Videos()
        }
    }

    const handleKeywordChange = (e) => {
        setKeyword(e.target.value);
        setPage360(1);
        setPageFootage(1);

        if (checkType === 'camera') {
            setVideos360([]);
        } else {
            setVideosFootage([]);
        }

    }

    const handleCheckType = (type) => {
        setCheckType(type);
        setKeyword('');
        setErrorMessage('');
    }

    const fetchVideos360 = () => {
        setErrorMessage('');
        if (keyword === '') {
            setLoad360(true);
        }

        axios({
            method: 'POST',
            url: `${baseURL}load-library-three-sixty-youtube`,
            data: { keyword: keyword, page_number: page360, type: 'video' },
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {
            if (res.data.status === true) {

                if (keyword === '') {
                    setVideos360(videos360.concat(res.data.data.youtube));
                } else {
                    setVideos360(res.data.data.youtube);
                }

                setPage360(page360 + 1);
            } else {
                setErrorMessage(res.data.message);
            }
            setLoader(false);
            setLoad360(false);
        }).catch(error => {
            setErrorMessage('Something went wrong, please try again');
            setLoader(false);
        })
    }

    const processBackground = (data) => {

        imageData.id = randomstring.generate({ length: 10, charset: 'numeric' });
        imageData.video_src = data.bg_file[0].path;
        imageData.src = data.bg_file[0].poster;

        if (data.hasOwnProperty('file_type')) {
            imageData.background_slide_type = 'image';

            delete data.file_type;
        } else {
            delete imageData.src;
            imageData.background_slide_type = 'video';
        }

        return imageData;
    }

    const handleFileChange = (e) => {
        if (e.target.files[0] !== undefined) {

            let allowedExt;

            if (checkType === 'five') {
                allowedExt = ['image/jpg', 'image/jpeg', 'image/png'];
            } else {
                allowedExt = ['video/mp4'];
            }

            if (allowedExt.includes(e.target.files[0].type)) {

                let allowedSize = 20000000;
                let type = "image_panorama";
                if (e.target.files[0].type === "video/mp4") {
                    allowedSize = 500000000;
                    type = "threesixty";
                }


                if (e.target.files[0].size < allowedSize) {
                    setUploadLoader(true);

                    const formData = new FormData();
                    formData.append('upload_type', type);
                    formData.append('user_id', auth?.user?.id);
                    formData.append('file', e.target.files[0])

                    const options = {
                        onUploadProgress: (progressEvent) => {
                            const { loaded, total } = progressEvent;
                            let percent = Math.floor((loaded * 100) / total);

                            setPercent(percent);
                        },
                        headers: {
                            'Authorization': auth.token
                        }
                    }

                    axios.post(`${baseURL}file-upload`, formData, options).then(res => {
                        if (res.data.status === true) {
                            fetchUserLibrary();
                        }
                        setUploadLoader(false);
                        setPercent(0);
                    })

                } else {
                    swal("Oops!", "Max allowed size for video 500MB and 20MB for Image");
                }

            } else {
                swal("Oops!", "You have Selected Invalid File Type");
            }
        }
    }

    const handleSelectVideo = async (data) => {
        setPageLoader(true);

        let objData;
        objData = await processBackground(data);
        data.layers = [objData].flat();

        if (type === 'regular') {
            data.images_data = [regularData].flat();

            let imagCanData = { ...imageData };

            imagCanData.id = randomstring.generate({ length: 10, charset: 'numeric' });
            imagCanData.video_src = regularData.path;
            delete imagCanData.src;
            delete imagCanData.scaleX;
            delete imagCanData.scaleY;
            delete imagCanData.hover_video;
            delete imagCanData.background_slide_type;

            let arr = data.layers;

            data.layers = arr.concat(imagCanData);
        }

        if (type === 'five') {
            data.is_panorama = true;
        }

        axios({
            method: 'POST',
            url: `${baseURL}create-project`,
            data: data,
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {

            if (res.data.status === true) {
                dispatch(addCampaignData(res.data.data));
                history.push(`/editor?id=${res.data.data.project_id}`);
            } else {
                dispatch(setAlert(res.data.message, 'danger'))
            }
            setPageLoader(false);
        }).catch(err => {
            dispatch(setAlert('Getting internal server error', 'danger'))
            setPageLoader(false);
        })
    }

    const fetchProjects = () => {
        axios({
            method: "POST",
            url: `${baseURL}fetch-all-projects`,
            data: {},
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {
            if (res.data.status === true) {
                setCampData(res.data.data);
            }
        }).catch(err => {

        })
    }

    const fetchUserLibrary = () => {
        setUserLoader(true);

        axios({
            method: "POST",
            url: `${baseURL}fetch-user-assets`,
            data: { user_id: auth.user !== null ? auth.user.id : '' },
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {
            if (res.data.status === true) {
                setVideosUpload(res.data.data.threesixtyvideo);
                setPanaImages(res.data.data.PanoramaImages);
            }

            setUserLoader(false);
        }).catch(err => {
            setUserLoader(false)
        })
    }

    const fetch360Videos = () => {
        setErrorMessage('');

        if (keyword === '') {
            setLoadFootage(true);
        }

        axios({
            method: "POST",
            url: `${baseURL}load-library-three-sixty-bg`,
            data: { keyword: keyword, type: "video", page_number: pageFootage },
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {
            if (res.data.status === true) {

                if (keyword === '') {
                    setVideosFootage(videosFootage.concat(res.data.data));
                } else {
                    setVideosFootage(res.data.data);
                }

                setPageFootage(pageFootage + 1);
            } else {
                setErrorMessage(res.data.message);
            }
            setLoader(false);
            setLoadFootage(false);
        }).catch(err => {
            setErrorMessage('Something went wrong, please try again');
            setLoader(false);
        })
    }


    useEffect(() => {
        if (typeParam !== undefined) {
            setType(typeParam);
            setCheckType(typeParam);
        }
    }, [])

    useEffect(() => {
        fetchUserLibrary();
        fetchProjects();
        fetchVideos360();
        fetch360Videos();

        return () => {
            setVideos360([]);
            setVideosUpload([]);
            setVideosFootage([]);
            setCampData([]);
        }
    }, [])


    return (
        <>
            <Helmet>
                <title>{brandName !== false ? brandName.name : appName} | MetaVerse Video </title>
            </Helmet>
            <Alert />

            <div className="meta-lib-page-body">
                <div className="meta-lib-page-main">

                    <Header />

                    <section id="videos-wrapper" className="videos-wrapper mb-5" >
                        <div className="custom-wrapper container">

                            {
                                checkType === 'five' ?
                                    <h6 className="mb-3 tabDesc">Please Select / Upload a Panaromic Image</h6>
                                    : ''
                            }

                            {
                                type === 'one' || type === 'two' || type === 'four' || type === 'regular' ?
                                    <h6 className="mb-3 tabDesc">Please Select a Background VR Video</h6>
                                    : ''
                            }

                            {
                                checkType !== 'five' ?
                                    <div className="video-menu-tabs">
                                        <div className="list-group mx-0" id="list-tab" role="tablist">
                                            {
                                                type === 'five' ?
                                                    <a onClick={() => handleCheckType('five')} className={`md-txt list-group-item list-group-item-action ${checkType === 'five' ? 'active' : ''}`} data-toggle="list" href="#list-panaromic" role="tab" aria-controls="Panaromic Videos">
                                                        Panaromic Images
                                                    </a>
                                                    : ''

                                            }
                                            {
                                                type === 'four' ?
                                                    <>
                                                        <a onClick={() => handleCheckType('three')} className={`md-txt list-group-item list-group-item-action ${checkType === 'three' ? 'active' : ''}`} data-toggle="list" href="#list-uploadVids" role="tab" aria-controls="Upload Videos">
                                                            Upload Videos
                                                        </a>
                                                    </>
                                                    : <>
                                                        <a onClick={() => handleCheckType('one')} className={`md-txt list-group-item list-group-item-action ${checkType === 'one' || checkType === 'two' || checkType === 'regular' ? 'active' : ''}`} data-toggle="list" href="#list-camera-footage" role="tab" aria-controls="camera-footage">
                                                            3D VR Videos
                                                        </a>
                                                        <a onClick={() => handleCheckType('camera')} className={`md-txt list-group-item list-group-item-action ${checkType === 'camera' ? 'active' : ''} `} data-toggle="list" href="#list-3dVideos" role="tab" aria-controls="3d-videos">
                                                            Camera Footage
                                                        </a>
                                                        <a onClick={() => handleCheckType('three')} className={`md-txt list-group-item list-group-item-action ${checkType === 'three' ? 'active' : ''}`} data-toggle="list" href="#list-uploadVids" role="tab" aria-controls="Upload Videos">
                                                            Upload Videos
                                                        </a>
                                                    </>

                                            }

                                            {
                                                type === 'four' ?
                                                    <a onClick={() => handleCheckType('four')} className={`md-txt list-group-item list-group-item-action ${checkType === 'four' ? 'active' : ''}`} data-toggle="list" href="#list-metaverse" role="tab" aria-controls="Metaverse Videos">
                                                        Your Campaign
                                                    </a>
                                                    : ''
                                            }
                                        </div>
                                    </div>
                                    : ''
                            }
                            {
                                checkType !== 'four' ?
                                    <form onSubmit={(e) => handleFormSubmit(e)} method="POST">
                                        {
                                            checkType === 'three' ?
                                                <div className="search-wrapper alt">
                                                    <div className="alert alert-warning alertModify mb-2">
                                                        <strong>Note: </strong> To get a high quality output, make sure the video you convert into VR video, is a minimum on 1080p or higher (1920x1080). VR Videos play well on 4k only.
                                                    </div>

                                                    <div className="alert alert-warning alertModify">
                                                        <strong>Note: </strong> Please upload a 360 Degree VR video only. Uploading any video other then VR video will not produce the expected output
                                                    </div>
                                                </div>
                                                : ''
                                        }

                                        {
                                            checkType === 'five' ?
                                                <div className="search-wrapper alt">
                                                    <div className="alert alert-warning alertModify">
                                                        <strong>Note: </strong> Please upload a Panaromic image only. Uploading any image other then Panaromic image will not produce the expected output
                                                    </div>
                                                </div>
                                                : ''
                                        }

                                        <div className="search-wrapper d-flex align-items-center w-100 search-videos">

                                            {
                                                checkType === 'three' || checkType === 'five' ?
                                                    <>

                                                        <div className="d-flex align-items-center">

                                                            <label className="search-video"> Choose File
                                                                <input type="file" name="search" onChange={(e) => handleFileChange(e)} placeholder="Search here for videos" className="search-box input-file" />
                                                            </label>
                                                            <span className="mp4Al">{checkType === 'five' ? ' .jpg, .jpeg, .png ' : '.mp4'} file upto {checkType === 'five' ? '20' : '500'}MB is allowed</span>

                                                        </div>

                                                    </>
                                                    :
                                                    <>
                                                        <div className="img-wrapper">
                                                            <img className="img-fluid" src={searchIcon} alt="Search Icon" />
                                                        </div>
                                                        <input type="text" name="search" onChange={(e) => handleKeywordChange(e)} placeholder="Search here for videos"
                                                            className="search-box" />
                                                    </>
                                            }
                                            <button type="submit" className="fs-18 btn search-btn">GO</button>
                                        </div>
                                        {
                                            loader ? <div className="text-center">
                                                <FontAwesomeIcon icon={faSpinner} style={{ fontSize: "30px", color: "#fff" }} />
                                            </div>
                                                : ''
                                        }
                                        {
                                            uploadLoader ?
                                                <><div className="progress mt-4">
                                                    <div className="progress-bar" role="progressbar" style={{ width: `${percent}%`, backgroundColor: "#00a5b8" }} aria-valuenow={`${percent}`}
                                                        aria-valuemin="0" aria-valuemax="100">{percent > 5 ? percent + '%' : ''}
                                                    </div>
                                                </div><br /><br /></>
                                                : ''
                                        }
                                    </form>
                                    : ''
                            }
                            <div className={`tab-content video-tab-content ${checkType === 'camera' ? 'mt-5' : ''}`} id="nav-tabContent">
                                <div className={`tab-pane fade ${checkType === 'one' || checkType === 'two' || checkType === 'regular' ? 'show active' : ''}`} id="list-camera-footage" role="tabpanel" aria-labelledby="list-camera-footage">
                                    <MetaVerseFootage videos={videosFootage} handleSelectVideo={handleSelectVideo} user={auth.user} fetch360Videos={fetch360Videos} type={type} error={errorMessage} loader={loadFootage} />
                                </div>
                                <div className={`tab-pane fade ${checkType === 'camera' ? 'show active' : ''}`} id="list-3dVideos" role="tabpanel" aria-labelledby="list-3dVideos">
                                    <MetaVerseVideo videos={videos360} handleSelectVideo={handleSelectVideo} user={auth.user} type={type} fetchVideos360={fetchVideos360} error={errorMessage} loader={load360} key={1} />
                                </div>
                                <div className={`tab-pane fade ${checkType === 'three' ? 'show active' : ''}`} id="list-uploadVids" role="tabpanel" aria-labelledby="list-uploadVids">
                                    {
                                        userLoader ?
                                            <div className="text-center">
                                                <FontAwesomeIcon icon={faSpinner} style={{ fontSize: "30px", color: "#fff" }} />
                                            </div>
                                            : <MetaVerseUpload handleSelectVideo={handleSelectVideo} user={auth} videos={videosUpload} type={type} fetchUserLibrary={fetchUserLibrary} />
                                    }
                                </div>
                                <div className={`tab-pane fade ${checkType === 'four' ? 'show active' : ''}`} id="list-metaverse" role="tabpanel" aria-labelledby="list-metaverse">
                                    <MetaVerseCampaign handleSelectVideo={handleSelectVideo} user={auth} videos={campData} type={type} />
                                </div>
                                <div className={`tab-pane fade ${checkType === 'five' ? 'show active' : ''}`} id="list-panaromic" role="tabpanel" aria-labelledby="list-panaromic">
                                    {
                                        userLoader ?
                                            <div className="text-center">
                                                <FontAwesomeIcon icon={faSpinner} style={{ fontSize: "30px", color: "#fff" }} />
                                            </div>
                                            : <PanaromicUpload handleSelectVideo={handleSelectVideo} user={auth} videos={panaImages} type={type} fetchUserLibrary={fetchUserLibrary} />
                                    }
                                </div>
                            </div>
                        </div>
                    </section>

                    <Footer />

                    {
                        pageLoader ?
                            <div className="loader-sec">
                                <div className="loader">
                                </div>
                            </div>
                            : ''
                    }

                </div>
            </div>

        </>
    )
}

export default React.memo(CreateMetaVerse);
