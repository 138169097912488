import React, { useState } from "react";
import axios from "axios";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { fetchSocialAccounts, saveFacebook } from "../../actions/socialAction";
import { baseURL } from "../../global/global";
import { setAlert } from "../../actions/alert";
import { useDispatch, useSelector } from "react-redux";
import facebookEnable from "../../images/logo-facebook.svg";
import facebookDisable from "../../images/FB_DISABLE.png";
import SweetAlert from "react-bootstrap-sweetalert";

const ConnectFacebook = (props) => {

    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const [loader, setLoader] = useState(false);

    const [intData, setIntData] = useState({
        id: '',
        user_id: auth.user !== null ? auth.user.id : ''
    })

    // Sweetalert
    const [button, setButton] = useState('Disconnect');
    const [showDelete, setShowDelete] = useState(false);

    const deleteIntegration = (id) => {
        setIntData({ ...intData, id: id });
        setShowDelete(true);
    }

    const onConfirm = () => {
        setButton('Disconnecting...');
        deleteSocialAccounts();
    }

    const deleteSocialAccounts = () => {
        axios({
            method: "POST",
            url: `${baseURL}delete-social-network`,
            data: intData,
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {
            if (res.data.status === true) {
                dispatch(setAlert(res.data.message, 'success'));
                dispatch(fetchSocialAccounts(auth.token));
            } else {
                dispatch(setAlert(res.data.message, 'danger'));
            }
            setShowDelete(false);
            setButton('Delete');
        }).catch(err => {
            setShowDelete(false);
            setButton('Delete');
        })
    }

    const onCancel = () => {
        setShowDelete(false);
    }

    const [details, setDetails] = useState({
        name: false,
        userId: false,
        accessToken: false,
        openPopup: false,
        buttonText: "Connect",
        email: false,
        pages: [],
    });

    const [dropValue, setDropValue] = useState("Select An Account");
    const [selection, setSelection] = useState({
        type: false,
        id: false,
        accessToken: false,
        name: false,
    });

    const [groups, setGroups] = useState([]);

    const closeConnectModel = () => {
        setDetails({ ...details, openPopup: false });
    };

    const handleChange = (e) => {
        const selectedValue = e.target.value.split("___CR___");
        setSelection({
            type: selectedValue[2],
            id: selectedValue[3],
            accessToken: selectedValue[1],
            name: selectedValue[0],
        });
        setDropValue(e.target.value);
    };

    const handleClick = async () => {
        setLoader(true);
        const data = {
            user_id: auth.user.id,
            title: selection.name,
            token: selection.accessToken,
            fb_id: selection.id,
            category: selection.type,
            email: details.email,
        };

        await dispatch(saveFacebook(data));
        setDetails({ ...details, openPopup: false });
        setLoader(false);
    };

    const responseFacebook = async (response) => {
        try {
            const res = await axios.get(
                `https://graph.facebook.com/me/accounts?type=page&access_token=${response.accessToken}`
            );

            setDetails({
                ...details,
                name: response.name,
                userId: response.userID,
                openPopup: true,
                buttonText: "Connect",
                accessToken: response.accessToken,
                pages: res.data.data,
            });
        } catch (err) {
            console.log(err);
        }

        /** Fetch FB Groups */
        try {
            const res = await axios.get(
                " https://graph.facebook.com/me/groups?access_token=" + response.accessToken
            );
            console.log(res);

            setGroups(res.data.data);
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <>
            <div className="row justify-content-between align-items-center row-1" id="facebook">
                <div className="col-lg-1 col-12">
                    <div className="img-wrapper">
                        <img src={props.data.length > 0 ? facebookEnable : facebookDisable} className="mx-auto img-fluid" width={76} height={76} />
                    </div>
                </div>
                {
                    props.data.length > 0 ?
                        props.data.map((item, index) => (
                            <>
                                <div className="col-lg-7 col-12">
                                    <div className="text-wrapper">
                                        <p className="md-txt">Facebook</p>
                                        <p className="md-txt">{item?.username} - Added on {item.created}</p>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-12 text-right">
                                    <div className="row justify-content-end btns">
                                        <button className="btn-connect btn-2" onClick={(e) => deleteIntegration(item.id)}>Disconnect</button>
                                    </div>
                                </div>
                            </>
                        ))
                        :
                        <>
                            <div className="col-lg-7 col-12">
                                <div className="text-wrapper">
                                    <p className="md-txt">Facebook</p>
                                    <p className="md-txt">Not Connected</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-12 text-right"></div>
                        </>
                }
                <div className="col-12 text-center mt-4">
                    <FacebookLogin
                        appId="780210132546000" //APP ID NOT CREATED YET
                        fields="name,email"
                        // scope="pages_manage_posts,publish_to_groups,pages_read_user_content"
                        scope="pages_manage_posts,pages_read_user_content,pages_show_list,publish_video,pages_manage_engagement"
                        callback={(e) => responseFacebook(e)}
                        render={renderProps => (
                            <button onClick={renderProps.onClick} className="btn-connect btn-1">Connect</button>
                        )}
                    />
                </div>
            </div>

            <div className={`demo-modal modal ${details.openPopup ? 'show-drop' : ''} `}>
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content"
                    // style={{maxWidth: "500px", margin: "auto"}}
                    >
                        <div className="modal-header">
                            <h5 className="modal-title">
                                Connect Facebook
                            </h5>
                            <button onClick={(e) => closeConnectModel()} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">
                                    <i className="fas fa-times-circle" style={{ color: "#ffffff" }} ></i></span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form className="add-account-box mt-2" style={{ backgroundColor: "transparent" }}>
                                <select className="form-control" id="exampleFormControlSelect1" onChange={handleChange}
                                    value={dropValue}>
                                    <option value="Select an Account" >Select An Account</option>
                                    <optgroup label="Your Facebook Pages">
                                        {details.pages.map((page) => (
                                            <option
                                                key={page.id}
                                                value={
                                                    page.name +
                                                    "___CR___" +
                                                    page.access_token +
                                                    "___CR___page___CR___" +
                                                    page.id
                                                }
                                            >
                                                {page.name}
                                            </option>
                                        ))}
                                    </optgroup>
                                    <optgroup label="Your Facebook Groups">
                                        {groups.map((group) => (
                                            <option
                                                key={group.id}
                                                value={
                                                    group.name +
                                                    "___CR___" +
                                                    details.accessToken +
                                                    "___CR___group___CR___" +
                                                    group.id
                                                }
                                            >
                                                {group.name}
                                            </option>
                                        ))}
                                    </optgroup>
                                </select>
                            </form>
                        </div>
                        <div className="modal-footer d-flex justify-content-center ">
                            <div className="col-md-12 mt-3 d-flex justify-content-center align-items-center ">
                                <button style={{ padding: "10px" }} type="submit" className="btn-connect btn-1" onClick={handleClick} disabled={dropValue === "Select An Account" ? true : false}>
                                    {loader ? <i className="fa fa-spinner fa-spin mr-2" /> : ''} Connect
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <SweetAlert
                warning
                showCancel
                confirmBtnText={button}
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="light"
                title="Are you sure?"
                onConfirm={(e) => onConfirm(e)}
                onCancel={(e) => onCancel(e)}
                focusCancelBtn
                show={showDelete}
            >
                Do you want to disconnect this Facebook account?
            </SweetAlert>

        </>
    )
}

export default React.memo(ConnectFacebook);