import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { baseURL } from "../../global/global";
import { setAlert } from "../../actions/alert";
import Alert from "../Alert";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AddAccount = (props) => {

    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();

    const [loader, setLoader] = useState(false);
    const [userDetails, setUserDetails] = useState({
        type: 'client',
        email: '',
        name: '',
        password: ''
    });
    const [passwordMsg, setPasswordMsg] = useState({
        msg: "",
        validation: false
    })
    const onInputChange = (e) => {
        setUserDetails({ ...userDetails, [e.target.name]: e.target.value });
    }

    const handleFormSubmit = (e) => {
        e.preventDefault();

        const pattern = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9\s]).{6,}$/;
        if (userDetails.password && pattern.test(userDetails.password)) {
            setLoader(true);
            axios({
                method: "POST",
                url: `${baseURL}add-client`,
                data: userDetails,
                headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
            }).then(res => {
                console.log(res.data, "gdfgbfj")
                if (res.data.status === true) {
                    dispatch(setAlert(res.data.message, 'success'));
                    props.fetchClientTeam();
                } else {
                    dispatch(setAlert(res.data.message, 'danger'));
                }
                setLoader(false);
            }).catch(error => {
                setLoader(false);
            })
            setPasswordMsg({ ...passwordMsg, validation: false, msg: "" })
        } else {
            setPasswordMsg({ ...passwordMsg, msg: "Password must contain at least 1 lowercase letter, 1 uppercase letter, 1 digit, 1 alphanumeric and be at least 8 characters long.", validation: true })
        }

    }

    return (
        <>
            <Alert />
            <section id="create-acc-sec" className="create-acc-sec">
                <div className="custom-wrapper container">
                    <div className="content-wrapper">
                        <p className="h4 mb-4">Create Account</p>
                        <div className="form-wrapper">
                            <form onSubmit={(e) => handleFormSubmit(e)} method="POST" className="create-acc-form">
                                <div className="row mx-0 justify-content-between mb-4">
                                    <div className="form-group colmn colmn-1 mb-0">
                                        <p className="fs-15 f-300" style={{ color: '#C8D8E9' }}>Account</p>
                                        <select name="type" onChange={(e) => onInputChange(e)} className="form-control client-account">
                                            <option value="client">Client Account</option>
                                            <option value="team_member">Team Member Account</option>
                                        </select>
                                        {/*<input type="email" name="client-account" className="client-account" defaultValue="Client Account" placeholder="Client Account" />*/}
                                    </div>
                                    <div className="form-group colmn colmn-2 mb-0">
                                        <p className="fs-15 f-300" style={{ color: '#C8D8E9' }}>Account Name</p>
                                        <input type="text" name="name" onChange={(e) => onInputChange(e)} className="account-name" defaultValue="Roberto" placeholder="Account Name" required />
                                    </div>
                                </div>
                                <div className="row mx-0 justify-content-between">
                                    <div className="form-group colmn colmn-1 mb-0">
                                        <p className="fs-15 f-300" style={{ color: '#C8D8E9' }}>Account Email</p>
                                        <input type="email" name="email" onChange={(e) => onInputChange(e)} className="account-email" defaultValue="Roberto@vega6.com" placeholder="Roberto@email.com" required />
                                    </div>
                                    <div className="form-group colmn colmn-2 mb-0">
                                        <p className="fs-15 f-300" style={{ color: '#C8D8E9' }}>Account Password</p>
                                        <input type="password" name="password" onChange={(e) => onInputChange(e)} className="account-name" defaultValue="*******" placeholder="*******" required />
                                    </div>
                                    <p style={{ fontSize: 13, margin: "5px", color: "#DC143C" }}>{passwordMsg.validation ? passwordMsg.msg : ""}</p>

                                </div>
                                <button disabled={loader} type="submit" className="create-acc-submit md-txt f-500">
                                    {loader ? <FontAwesomeIcon icon={faSpinner} /> : ''} Create
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default React.memo(AddAccount)