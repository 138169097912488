import randomstring from "randomstring";

export const baseURL = "https://backend.virtualreel.io/";
export const appName = "VirtualReel"
export const fontJson = {
    id: parseInt(randomstring.generate({ length: 5, charset: 'numeric' })),
    text: 'Enter Your Text Here',
    color: '#ffffff',
    bgColor: '',
    bgOpcity: 0,
    startTime: 0,
    endTime: 10,
    fontFamily: 'Arial',
    fontSize: 18,
    fontSizeLimit: 42,
    fontAlign: 'left',
    lineHeight: 1,
    bold: false,
    italic: false,
    uppercase: false,
    lowercase: false,
    underline: false,
    strike: false,
    position: [0, 0],
    is_selected: true
}

export const imageData = {
    id: parseInt(randomstring.generate({ length: 10, charset: 'numeric' })),
    type: 'image',
    version: '3.4.0',
    originX: 'left',
    originY: 'top',
    left: 0,
    top: 0,
    width: 100,
    height: 80,
    fill: "rgb(0,0,0)",
    stroke: null,
    strokeWidth: 0,
    strokeDashArray: null,
    strokeLineCap: 'butt',
    strokeDashOffset: 0,
    strokeLineJoin: "milter",
    strokeMiterLimit: 4,
    scaleX: 0.93,
    scaleY: 0.93,
    angle: false,
    flipY: false,
    opacity: 1,
    shadow: null,
    visible: true,
    clipTo: null,
    backgroundColor: "",
    fillRule: 'nonzero',
    paintFirst: 'fill',
    globalCompositeOperation: 'source-over',
    transformMatrix: null,
    skewX: 0,
    skewY: 0,
    crossOrigin: "anonymous",
    cropX: 0,
    cropY: 0,
    video_src: false,
    src: false,
    background_slide_type: 'video',
    filters: [],
    is_selected: true
}


export const layerTextData = {
    id: parseInt(randomstring.generate({ length: 10, charset: 'numeric' })),
    type: 'textbox',
    version: '3.4.0',
    originX: 'left',
    originY: 'top',
    left: 0,
    top: 0,
    width: 220,
    height: 50,
    fill: '#ffffff',
    stroke: null,
    strokeWidth: 1,
    strokeDashArray: null,
    strokeLineCap: "butt",
    strokeDashOffset: 0,
    strokeLineJoin: "miter",
    strokeMiterLimit: 4,
    scaleX: 1,
    scaleY: 1,
    angle: 0,
    flipX: false,
    flipY: false,
    opacity: 1,
    shadow: null,
    visible: true,
    clipTo: null,
    backgroundColor: false,
    fillRule: "nonzero",
    paintFirst: "fill",
    globalCompositeOperation: "source-over",
    transformMatrix: null,
    skewX: 0,
    skewY: 0,
    text: "Enter Your Text Here",
    fontSize: 18,
    fontWeight: "normal",
    fontFamily: "Arial",
    fontStyle: "normal",
    lineHeight: 1.16,
    underline: false,
    overline: false,
    linethrough: false,
    textAlign: "center",
    textBackgroundColor: false,
    charSpacing: 0,
    minWidth: 20,
    splitByGrapheme: false,
    is_selected: true,
    styles: {}
}