import React, { useEffect, useState } from "react";
import banner from "../../images/s3-bg.png";
import axios from "axios";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addArticles } from "../../actions/supportAction";

const HelpSearch = () => {

    const dispatch = useDispatch();

    const [articles, setArticles] = useState([]);
    const [loadArticle, setLoadArticle] = useState(false);

    const [q, setQ] = useState('');

    const onSearch = (e) => {
        setQ(e.target.value);
    }

    const search = (articles) => {
        return articles.filter(
            article => article.tags.toLowerCase().indexOf(q.toLowerCase()) > -1
        );
    }

    const handleFormSubmit = (e) => {
        e.preventDefault();
    }

    const fetchArticle = () => {
        setLoadArticle(true);

        axios({
            method: "POST",
            url: "https://adminserver.reelapps.io/api/fetch-article",
            data: JSON.stringify({ app_id: 40 }),
            headers: { 'Content-Type': 'application/json' }
        }).then(res => {
            if (res.data.status === true) {
                setArticles(res.data.data);
                dispatch(addArticles(res.data.data));
            }
            setLoadArticle(false);
        }).catch(err => {
            setLoadArticle(false);
        })
    }

    useEffect(() => {
        fetchArticle();
    }, [])

    return (
        <>
            <section id="banner" className="banner" style={{ background: `url(${banner})` }}>
                <div className="custom-wrapper">
                    <div className="custom-content mx-auto text-center">
                        <h2 className="f-700 f-white">What do you want to know?</h2>
                        <div className="search-box">
                            <form onSubmit={(e) => handleFormSubmit(e)} method="POST">
                                <div className="d-flex align-items-center justify-content-between">
                                    <div className="search-icon">
                                        <svg id="search" xmlns="http://www.w3.org/2000/svg" width="27.946"
                                            height="27.946" viewBox="0 0 27.946 27.946">
                                            <g id="Group_19" data-name="Group 19">
                                                <path id="Path_35" data-name="Path 35"
                                                    d="M19.973,17.576H18.7l-.439-.439a10.311,10.311,0,1,0-1.126,1.134l.439.439v1.262l7.989,7.973,2.381-2.381Zm-9.587,0a7.19,7.19,0,1,1,7.19-7.19A7.191,7.191,0,0,1,10.386,17.576Z"
                                                    fill="#dcdcdc" />
                                            </g>
                                        </svg>
                                    </div>

                                    <input type="text" placeholder="Search" className="search" onChange={(e) => onSearch(e)} />

                                    {/* <button type="submit" className="search-submit md-txt f-600" id="search-submit">
                                        Submit
                                    </button> */}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>

            <section className="sec-1" id="sec-1">
                <div className="custom-wrapper">
                    <div className="custom-content">
                        <div className="title text-center">
                            <p className="h2 f-700">Important Topics</p>
                        </div>
                        <div className="content">
                            {search(articles).length > 0 ?
                                <div className="row">

                                    {
                                        articles.length > 0 ?
                                            search(articles).map((item, index) => (
                                                <div className="col-lg-3 col-md-6 mb-4">
                                                    <Link to={`/training?id=${item.id}`} className="colmn colmn-1" key={index}>
                                                        <div className="important-topics-content d-blockalign-items-center text-center">
                                                            <div className="img-wrapper">
                                                                <img src={item?.logo} className="mx-auto img-fluid" />
                                                            </div>
                                                            <p className="fs-18 f-600">{item?.name}</p>
                                                        </div>

                                                    </Link>
                                                </div>
                                            ))
                                            : ''
                                    }

                                </div> : <div style={{ color: "white", fontSize: "30px", display: "flex", justifyContent: "center", alignItems: "center" }}>No data available</div>
                            }


                            {
                                loadArticle ? <div style={{ display: "block" }} className="row"><h6 className="text-center">Fetching Articles...</h6></div> : ''
                            }
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default React.memo(HelpSearch);