import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import './css/ac-styles.css';
import Header from "../Header";
import Footer from "../Footer";
import AddAccount from "./AddAccount";
import axios from "axios";
import { useSelector } from "react-redux";
import { appName, baseURL } from "../../global/global";
import TeamClientAccount from "./TeamClientAccount";

const AccountManagement = () => {

    const auth = useSelector(state => state.auth);
    const brandName = useSelector(state => state.rebrand.data);

    const [clients, setClients] = useState([]);
    const [teams, setTeams] = useState([]);

    const fetchClientTeam = () => {
        axios({
            method: "POST",
            url: `${baseURL}fetch-clients`,
            data: {},
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {
            if (res.data.status === true) {
                setTeams(res.data.data.team_members)
                setClients(res.data.data.clients)
            }
        }).catch(error => {

        })
    }

    useEffect(() => {
        fetchClientTeam();

        return () => {
            setClients([]);
            setTeams([]);
        }

    }, [])


    return (
        <>
            <Helmet>
                <title>{brandName !== false ? brandName.name : appName} | Account Management </title>
            </Helmet>

            <div className="ac-page-body">
                <main className="ac-page-main">
                    <Header />

                    <div className="privacy-account">

                        <AddAccount fetchClientTeam={fetchClientTeam} />

                        <TeamClientAccount type="Client" users={clients} fetchClientTeam={fetchClientTeam} key="1" />
                        <TeamClientAccount type="Team Member" users={teams} fetchClientTeam={fetchClientTeam} key="2" />

                    </div>

                    <Footer />
                </main>
            </div>
        </>
    )
}

export default React.memo(AccountManagement);