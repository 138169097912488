import React, { useEffect } from "react";
import './css/integration-styles.css';
import Header from "../Header";
import Footer from "../Footer";
import IntegrationHead from "./IntegrationHead";
import ConnectYoutube from "./ConnectYoutube";
import { useDispatch, useSelector } from "react-redux";
import { fetchSocialAccounts } from "../../actions/socialAction";
import ConnectFacebook from "./ConnectFacebook";
import { Helmet } from "react-helmet";
import ConnectReelapps from "./ConnectReelapps";
import trimreelIcon from "../../images/trimreel-icon.png";
import reelmergeIcon from "../../images/reelmerge-icon.png";
import randomstring from 'randomstring'

import trimDisable from "../../images/TRIM_DISABLE.png";

import trimEnable from "../../images/logo-trim.svg";
// import reelIcon from "../../images/reelmerge.png"
import reelEnable from "../../images/reelmerge-icon.png";
import reelDisable from "../../images/REELMERGE (Disable).png";
import { appName } from "../../global/global";

const Integration = () => {

    const dispatch = useDispatch();

    const auth = useSelector(state => state.auth);
    const socialData = useSelector(state => state.social);
    const brandName = useSelector(state => state.rebrand.data);

    useEffect(() => {
        dispatch(fetchSocialAccounts(auth.token));
    }, [])

    return (
        <>
            <Helmet>
                <title>{brandName !== false ? brandName.name : appName} | Integration </title>
            </Helmet>
            <div className="integration-page-body">
                <main className="integration-page-main">

                    <Header />

                    <div className="banner" id="banner">
                        <div className="custom-wrapper">
                            <div className="content-wrapper">
                                <IntegrationHead data={socialData} />
                                <div className="divider" />
                                <div className="connections">
                                    <div className="title">
                                        <p className="h3 f-600">My Connection</p>
                                    </div>
                                    <div className="content">
                                        <ConnectFacebook data={socialData.facebook} />
                                        <ConnectYoutube data={socialData.youtube} />
                                        {/* <ConnectReelapps icon={socialData.trimreelIcon }/>
                                        <ConnectReelapps icon={socialData.reelmergeIcon} /> */}
                                        <ConnectReelapps icon={socialData.trimreel.length > 0 ? trimEnable : trimDisable} name="TrimReel" type="trimreel" data={socialData.trimreel} key={randomstring.generate(7)} />
                                        <ConnectReelapps icon={socialData.reelmerge.length > 0 ? reelEnable : reelDisable} name="ReelMerge" type="reelmerge" data={socialData.reelmerge} key={randomstring.generate(7)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Footer />

                </main>
            </div>
        </>
    )
}

export default React.memo(Integration);