import React, { useState } from "react";
import editIcon from "../../images/edit.svg";
import trashIcon from "../../images/trash.svg";
import axios from "axios";
import { baseURL } from "../../global/global";
import { setAlert } from "../../actions/alert";
import { useDispatch, useSelector } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const EditTeamClient = ({ user, type, fetchClientTeam }) => {

    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);

    const [loader, setLoader] = useState(false);
    const [accountDetails, setAccountDetails] = useState({
        id: user.id,
        email: user.email,
        name: user.name,
        password: ''
    });

    const [deleteId, setDeleteId] = useState({
        client_id: null
    });
    const [passwordError, setPasswordError] = useState('');

    const [button, setButton] = useState('Delete');
    const [showDelete, setShowDelete] = useState(false);
    // const validatePassword = (password) => {
    //     const minLength = 8;
    //     const errors = [];

    //     if (password.length < minLength) {
    //         errors.push(`Password must be at least ${minLength} characters long.`);
    //     }
    //     if (!/[A-Z]/.test(password)) {
    //         errors.push('Password must contain at least one uppercase letter.');
    //     }
    //     if (!/[a-z]/.test(password)) {
    //         errors.push('Password must contain at least one lowercase letter.');
    //     }
    //     if (!/\d/.test(password)) {
    //         errors.push('Password must contain at least one number.');
    //     }
    //     if (!/[!@#$%^&*]/.test(password)) {
    //         errors.push('Password must contain at least one special character (e.g., !@#$%^&*).');
    //     }

    //     return errors.length > 0 ? errors.join(' ') : '';
    // };
    const validatePassword = (password) => {
        const minLength = 8;
        const hasUpperCase = /[A-Z]/.test(password);
        const hasLowerCase = /[a-z]/.test(password);
        const hasNumbers = /\d/.test(password);
        const hasSpecialChars = /[!@#$%^&*(),.?":{}|<>]/.test(password);
        if (password.length > 0) {
            if (password.length < minLength) return "Password must be at least 8 characters long.";
            if (!hasUpperCase) return "Password must contain at least one uppercase letter.";
            if (!hasLowerCase) return "Password must contain at least one lowercase letter.";
            if (!hasNumbers) return "Password must contain at least one number.";
            if (!hasSpecialChars) return "Password must contain at least one special character.";
        }
        return "";

    }

    const onConfirm = () => {
        deleteClientTeam();
    }

    const onCancel = () => {
        setShowDelete(false);
    }

    const onButtonClick = (e, id) => {
        e.preventDefault();

        setDeleteId({ ...deleteId, client_id: id });
        setShowDelete(true);
    }


    const onInputChange = (e) => {
        const { name, value } = e.target;

        setAccountDetails({ ...accountDetails, [name]: value });
        if (name === 'password') {
            const validationMessage = validatePassword(value);
            setPasswordError(validationMessage);
        }
    };

    const editClientTeam = () => {
        axios({
            method: "POST",
            url: `${baseURL}update-client`,
            data: accountDetails,
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {
            if (res.data.status === true) {
                fetchClientTeam()
                dispatch(setAlert(res.data.message, 'success'));
            } else {
                dispatch(setAlert(res.data.message, 'danger'));
            }
            setLoader(false);
        }).catch(err => {
            setLoader(false);
        })
    }

    const deleteClientTeam = () => {
        setButton('Deleting...');
        axios({
            method: "POST",
            url: `${baseURL}delete-client`,
            data: deleteId,
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {
            if (res.data.status === true) {
                fetchClientTeam()
                dispatch(setAlert(res.data.message, 'success'));
            } else {
                dispatch(setAlert(res.data.message, 'danger'));
            }
            setLoader(false);
            setButton('Delete');
        }).catch(err => {
            setLoader(false);
            setButton('Delete');
        })
    }

    // const onFormSubmit = (e) => {
    //     e.preventDefault();
    //     setLoader(true);

    //     editClientTeam();
    // }
    const onFormSubmit = (e) => {
        e.preventDefault();
        setLoader(true);
        const passwordValidationError = validatePassword(accountDetails.password);
        if (passwordValidationError) {
            setPasswordError(passwordValidationError);
            setLoader(false);
            return;
        }
        setPasswordError('');

        editClientTeam();
    };

    return (
        <>
            <tr>
                <td className="md-txt">{user.name}</td>
                <td className="md-txt">{user.email}</td>
                <td className="md-txt">{user.created}</td>
                {
                    auth.user !== null ?

                        auth.user.email !== user.email ?
                            <td className="action-btn d-flex align-items-center">
                                <a className="edit-icon mr-2"
                                    data-toggle="collapse" data-parent="accordion89"
                                    href={`#client_${user?.id}`}
                                    aria-expanded="true"
                                >
                                    <div className="img-wrapper">
                                        <img className="img-fluid" src={editIcon} alt="edit" />
                                    </div>
                                </a>
                                <a onClick={(e) => onButtonClick(e, user.id)} className="delete-icon cursor-pointer">
                                    <div className="img-wrapper">
                                        <img className="img-fluid" src={trashIcon} alt="trash" />
                                    </div>
                                </a>
                            </td>
                            : <td></td>
                        : ''
                }
            </tr>

            <tr className="hide-table-padding edit-user">
                <td colSpan="12" style={{ padding: "0px" }}>
                    <div id={`client_${user?.id}`} className="in collapse" style={{ width: "100%", padding: "0" }}>
                        <div className="row d-flex justify-content-center mt-3">
                            <div className="col-12 col-md-10">
                                <p className="text-white"></p>
                                <div className="mt-2 mb-0">
                                    <h6 className=" text-left mb-4 mt-3"
                                        style={{ marginLeft: "-10px" }}>Edit {type} Account</h6>
                                    <div>
                                        <form className="edit-acc-form" method="post" onSubmit={(e) => onFormSubmit(e)}>
                                            <div className="row d-flex justify-content-center">
                                                <div className="col col-lg-6 col-12 p-1 text-left">
                                                    <div className="form-group form-group-mb">
                                                        <label className="input-label-pos m-0">Name</label>
                                                        <input
                                                            className="form-control input-dark-accnt personal-info w-100"
                                                            type="text" autoComplete="off" name="name" defaultValue={accountDetails.name}
                                                            required onChange={(e) => onInputChange(e)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col col-lg-6 col-12 p-1 text-left">
                                                    <div className="form-group form-group-mb">
                                                        <label className="input-label-pos m-0">Email
                                                            Address</label>
                                                        <input
                                                            className="form-control input-dark-accnt personal-info"
                                                            type="email" name="email"
                                                            placeholder="Email Address" readOnly
                                                            defaultValue={user.email}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col col-lg-6 col-12 p-1 text-left">
                                                    <div className="form-group form-group-mb">
                                                        <label
                                                            className="input-label-pos m-0">Password <small>(leave
                                                                blank for same password)</small></label>
                                                        <input
                                                            className="form-control input-dark-accnt personal-info"
                                                            type="password" name="password"
                                                            placeholder="" autoComplete="off"
                                                            onChange={(e) => onInputChange(e)}
                                                        />
                                                        {passwordError && <div className="text-danger">{passwordError}</div>}

                                                    </div>
                                                </div>
                                                <div className="col col-lg-6 col-12 p-1 text-left">
                                                    <div className="form-group form-group-mb">
                                                        <label className="input-label-pos m-0">Account
                                                            Type</label>
                                                        <input
                                                            className="form-control input-dark-accnt personal-info"
                                                            type="text" name="Account Type"
                                                            placeholder="Account Type" readOnly
                                                            value={type} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="col col-md-12 cancel-update d-flex  justify-content-center text-center mt-5 mb-5">
                                                {/* <div className="btn-cancl">
                                                    <a type="button" className="btn btn-danger"
                                                    data-toggle="collapse"
                                                    data-parent="accordion89" href={`#client_${user.id}`}
                                                    aria-expanded="true"
                                                    style={{display: "inline-block",marginRight: "10px"}}>Cancel</a>
                                                </div> */}
                                                {/* <button type="submit"
                                                    className="btn btn-update"
                                                    style={{ display: "inline-block", textTransform: "capitalize" }}>
                                                    {loader ? <FontAwesomeIcon icon={faSpinner} /> : ''} Update
                                                </button> */}
                                                <button
                                                    type="submit"
                                                    className="btn btn-update"
                                                    style={{ display: "inline-block", textTransform: "capitalize" }}
                                                    disabled={loader}
                                                >
                                                    {loader ? <FontAwesomeIcon icon={faSpinner} /> : ''} Update
                                                </button>

                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </td>
            </tr>

            <SweetAlert
                warning
                showCancel
                confirmBtnText={button}
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="light"
                title="Are you sure?"
                onConfirm={(e) => onConfirm(e)}
                onCancel={(e) => onCancel(e)}
                focusCancelBtn
                show={showDelete}
            >
                Do you want to delete this {type}
            </SweetAlert>
        </>
    )
}
export default React.memo(EditTeamClient);


