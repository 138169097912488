import { combineReducers } from "redux";
import auth from "./authReducer";
import alert from "./alertReducer";
import support from "./supportReducer";
import currentLayer from "./editorReducer";
import social from "./socialReducer";
import script from "./scriptReducer";
import rebrand from "./rebrandReducer";
import timeline from "./timelineReducer";
import { WhiteLabelData } from "./WhiteLabelData";

// export default combineReducers({
//     auth,
//     alert,
//     support,
//     currentLayer,
//     social,
//     script,
//     rebrand,
//     timeline,
//     wl: WhiteLabelData,
// });
const appReducer = combineReducers({
    auth,
    alert,
    support,
    currentLayer,
    social,
    script,
    rebrand,
    timeline,
    wl: WhiteLabelData,
})



const rootReducers = (state, action) => {

    if (action.type === 'LOGOUT') {
        localStorage.clear();
        state = {
            ...state,
            auth: undefined,
            alert: undefined,
            support: undefined,
            currentLayer: undefined,
            social: undefined,
            script: undefined,
            timeline: undefined,
            rebrand: +state.auth?.user?.isWL === 1 ? state ? state.rebrand : undefined : undefined,

            wl: undefined,
        };
    }
    return appReducer(state, action)
}

export default rootReducers;
