import React, {useEffect, useState} from "react";
import defaultIcon from "../../images/aulbm.png";
import {useDispatch, useSelector} from "react-redux";
import {baseURL} from "../../global/global";
import axios from "axios";
import swal from "sweetalert";
import {addMetaVerseVideo} from "../../actions/editorAction";
import HoverVideoPlayer from "react-hover-video-player";
import {updateBackground} from "../../actions/timelineAction";
import {setAlert} from "../../actions/alert";
import SweetAlert from "react-bootstrap-sweetalert";

const UploadMetaVerse = () => {

    let allowedExt = ['video/mp4'];

    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const user_id = auth.user!==null?auth.user.id:'';

    const [loader, setLoader] = useState(false);
    const [percent, setPercent] = useState(0);
    const [video, setVideo] = useState([]);

    const [deleteId, setDeleteId] = useState({
        id: '',
        type: '',
        user_id: ''
    });
    const [button, setButton] = useState('Delete');
    const [showDelete, setShowDelete] = useState(false);

    const handleDelete = (e, id, type) => {
        e.stopPropagation();

        setDeleteId({...deleteId, id: id, type: type, user_id: user_id});
        setShowDelete(true);
    }

    const onConfirm = () => {
        setShowDelete(true);
        setButton('Deleting...');

        axios({
            method: "POST",
            url: `${baseURL}delete-media`,
            data: deleteId,
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {
            if (res.data.status === true) {
                fetchUserLibrary();
                dispatch(setAlert(res.data.message, 'success'));
            } else {
                dispatch(setAlert(res.data.message, 'danger'));
            }
            setShowDelete(false);
            setButton('Delete');
        }).catch(err => {
            setShowDelete(false);
            setButton('Delete');
        })
    }

    const fetchUserLibrary = () => {
        axios({
            method: "POST",
            url: `${baseURL}fetch-user-assets`,
            data: {user_id: user_id},
            headers: {'Content-Type': 'application/json','Authorization': auth.token}
        }).then(res=>{
            if(res.data.status === true){
                setVideo(res.data.data.threesixtyvideo);
            }
        }).catch(err=>{

        })
    }

    const handleFileUpload = (e) => {
        if (e.target.files[0] !== undefined) {
            if (allowedExt.includes(e.target.files[0].type)) {

                let allowedSize = 500000000;
                let type = "threesixty";


                if (e.target.files[0].size < allowedSize) {
                    setLoader(true);

                    const formData = new FormData();
                    formData.append('upload_type', type);
                    formData.append('user_id', user_id);
                    formData.append('file', e.target.files[0])

                    const options = {
                        onUploadProgress: (progressEvent) => {
                            const { loaded, total } = progressEvent;
                            let percent = Math.floor((loaded * 100) / total);

                            setPercent(percent);
                        },
                        headers: {
                            'Authorization': auth.token
                        }
                    }

                    axios.post(`${baseURL}file-upload`, formData, options).then(res => {
                        if (res.data.status === true) {
                            fetchUserLibrary();
                        }
                        setLoader(false);
                        setPercent(0);
                    })

                } else {
                    swal("Oops!", "Max allowed size for video 500MB");
                }

            } else {
                swal("Oops!", "You have Selected Invalid File Type");
            }
        }
    }

    const handleBgVideo = (url, poster) => {
        dispatch(addMetaVerseVideo(url))
        dispatch(updateBackground(url, poster))
    }

    useEffect(()=>{
        fetchUserLibrary();

        return () => {
            setVideo([]);
        }
    },[])

    return(
        <>
            <div className="row mt-2">
                <div className="col-md-12">
                    <div className="mv-upload-box">
                        <button type="button" className="form-control"><span className="mr-1"><i className="fas fa-cloud-upload-alt" /></span> Upload Media</button>
                        <div className="custom-file">
                            <input disabled={loader} type="file" className="custom-file-input" onChange={(e)=>handleFileUpload(e)} />
                        </div>
                    </div>
                </div>
            </div>
            {
                loader ?
                    <div className="progress mt-4">
                        <div className="progress-bar" role="progressbar" style={{ width: `${percent}%`, backgroundColor: "#00a5b8" }} aria-valuenow={`${percent}`}
                             aria-valuemin="0" aria-valuemax="100">{percent>5?percent+'%':''}
                        </div>
                    </div>
                    : ''
            }
            <div className="search-load-box mt-4 test1 mb-3">
                <div className="row">
                    {
                        video.length > 0 ?
                            video.map((item,index)=>(
                                <div className="colmn col-6 cursor-pointer" key={item.id} onClick={()=>handleBgVideo(item.video_url, item.poster)} >
                                    <div className="search-load d-flex justify-content-center align-items-center">
                                        <HoverVideoPlayer
                                            videoSrc={item.video_url}
                                            pausedOverlay={
                                                <img src={item?.poster ? item.poster : defaultIcon} alt={item.video_type} width="100%" />
                                            }
                                            loadingOverlay={
                                                <div className="loader-center"><i style={{ color: "#16d489" }} className="fa fa-spinner fa-spin mr-2" /></div>
                                            }
                                            className="video"
                                        />

                                    <div className="vid-dur" onClick={(e)=>handleDelete(e, item.id, 'threesixty')}>
                                        <div className="delete-icon icon">
                                                        <svg viewBox="0 0 448 512">
                                                            <path fill="currentColor" d="M268 416h24a12 12 0 0 0 12-12V188a12 12 0 0 0-12-12h-24a12 12 0 0 0-12 12v216a12 12 0 0 0 12 12zM432 80h-82.41l-34-56.7A48 48 0 0 0 274.41 0H173.59a48 48 0 0 0-41.16 23.3L98.41 80H16A16 16 0 0 0 0 96v16a16 16 0 0 0 16 16h16v336a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128h16a16 16 0 0 0 16-16V96a16 16 0 0 0-16-16zM171.84 50.91A6 6 0 0 1 177 48h94a6 6 0 0 1 5.15 2.91L293.61 80H154.39zM368 464H80V128h288zm-212-48h24a12 12 0 0 0 12-12V188a12 12 0 0 0-12-12h-24a12 12 0 0 0-12 12v216a12 12 0 0 0 12 12z"></path>
                                                        </svg>
                                                    </div>
                                        </div>

                                    </div>
                                </div>
                            ))
                        : ''
                    }
                </div>
            </div>

            <SweetAlert
                warning
                showCancel
                confirmBtnText={button}
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="light"
                title="Are you sure?"
                onConfirm={()=>onConfirm()}
                onCancel={() => setShowDelete(false)}
                focusCancelBtn
                show={showDelete}
            >
                You want to delete this file
            </SweetAlert>
        </>
    )
}

export default React.memo(UploadMetaVerse);