import React from "react";
import { Helmet } from "react-helmet";
import Header from "../Header";

import './css/tutorial-styles.css';
import Footer from "../Footer";
import HelpSearch from "./HelpSearch";
import HelpVideo from "./HelpVideo";
import { useSelector } from "react-redux";

import secIcon from "../../images/sec-3-img.png"
import { appName } from "../../global/global";

const HelpSupport = () => {

    const brandName = useSelector(state => state.rebrand.data);

    return (
        <>
            <Helmet>
                <title>{brandName !== false ? brandName.name : appName} | Help & Support </title>
            </Helmet>

            <div className="tutorial-page-body">
                <main className="tutorial-page-main">

                    <Header />

                    <HelpSearch />
                    <HelpVideo />


                    <section className="sec-3" id="sec-3">
                        <div className="custom-wrapper">
                            <div className="custom-content">
                                <div className="row justify-content-between align-items-center">
                                    <div className="col-lg-6 col-12 colmn colmn-1">
                                        <img src={secIcon} className="img-fluid mx-auto" />
                                    </div>
                                    <div className="col-lg-6 col-12 colmn colmn-2">
                                        <div className="content">
                                            <div className="text-wrapper">
                                                <p className="h3 f-300">CUSTOMER SUPPORT</p>
                                                <p className="h2 f-700">Can't find your answer?</p>
                                                <p className="md-txt f-300 w-87">We're here to help! From general
                                                    communication to specifc StoryReel platform questions, please reach
                                                    out so we can assist.</p>
                                            </div>
                                            <div className="contact">
                                                <a target="_blank" href="https://support.vineasx.com/" className="md-txt f-600">Contact Our Helpdesk</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <Footer />

                </main>
            </div>
        </>
    )
}

export default HelpSupport;