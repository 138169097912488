
export const setAlert = (message, type) => dispatch => {
    dispatch(removeAlert());
    dispatch({ type: 'SET_ALERT', payload: { message, type } });
    setTimeout(() => dispatch(removeAlert()), 2000);
}

// Remove Alert

export const removeAlert = () => dispatch => {
    dispatch({
        type: 'REMOVE_ALERT'
    })
}
