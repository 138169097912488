import React, { useEffect, useState } from "react";
import axios from "axios";
import { baseURL } from "../../global/global";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../../actions/alert";
import Alert from "../Alert";
import { addTtsAudio } from "../../actions/editorAction";
import { faSpinner, faFileUpload, faPlay, faPause, faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

let ttsAudio = false;
const TtsAudio = () => {

    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);

    const [loader, setLoader] = useState(false);

    const [currentTts, setCurrentTts] = useState('');
    const [languages, setLanguages] = useState([]);
    const [voices, setVoices] = useState([]);
    const [getStatus, setGenStatus] = useState(false);
    const [ttsData, setTtsData] = useState({
        language: 'en-US',
        voice: 'Joey',
        text: '',
        name: ''
    });
    const [ttsUrl, setTtsUrl] = useState('');

    const handleInputText = (e) => {
        setGenStatus(false);
        setTtsData({ ...ttsData, [e.target.name]: e.target.value });
    }

    const handleLanguage = (e) => {
        setGenStatus(false);
        let voices = languages.filter(lang => lang.language_code === e.target.value);

        setTtsData({ ...ttsData, language: e.target.value, voice: voices[0].voices[0].voice_id });
        setVoices(voices[0].voices);
    }


    const fetchTtsVoices = () => {
        axios({
            method: 'POST',
            url: `${baseURL}get-polly-languages`,
            data: { user_id: '' },
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {
            if (res.data.status === true) {
                setLanguages(res.data.data);
                setVoices(res.data.data[0].voices);
            }
        }).catch(err => {

        })
    }

    const handleGenTts = () => {

        setLoader(true);
        ttsData.user_id = auth.user?.id;

        axios({
            method: 'POST',
            url: `${baseURL}generate-tts`,
            data: ttsData,
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {
            if (res.data.status === true) {
                setGenStatus(true);
                setTtsUrl(res.data.url);
            } else {
                dispatch(setAlert(res.data.message, 'danger'));
            }

            setLoader(false);
        }).catch(err => {
            setLoader(false);
        })
    }

    const handleSaveTts = () => {
        dispatch(addTtsAudio(ttsUrl));
        dispatch(setAlert('TTS added Successfully', 'success'));
    }

    const playTts = (url = ttsUrl) => {

        if (ttsAudio !== false) {
            ttsAudio.pause();
        }

        if (currentTts === url) {
            ttsAudio.pause();
            setCurrentTts('')
        } else {
            ttsAudio = new Audio(url);

            ttsAudio.play().then(r => console.log('playing...'));

            ttsAudio.onended = function () {
                setCurrentTts('')
            }
            setCurrentTts(url);
        }
    }

    useEffect(() => {
        fetchTtsVoices();
    }, [])

    return (
        <>
            <Alert />
            <div className="tts-box-wrapper">
                <div className="row">
                    {/*<div className="col-sm-4">*/}
                    {/*    <div className="audio-slide-box">*/}
                    {/*        <div className="form-group text-center">*/}
                    {/*            <select className="form-select">*/}
                    {/*                <option selected>1</option>*/}
                    {/*                <option>2</option>*/}
                    {/*                <option>3</option>*/}
                    {/*                <option>4</option>*/}
                    {/*            </select>*/}
                    {/*            <label className="mt-2">SLIDE</label>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div className="col-sm-6">
                        <div className="audio-language-box">
                            <div className="form-group text-center">
                                <select onChange={(e) => handleLanguage(e)} className="form-select">
                                    {
                                        languages.length > 0 ?
                                            languages.map((item, index) => (
                                                <option key={index} value={item.language_code}>{item.name}</option>
                                            ))
                                            : ''
                                    }
                                </select>
                                <label className="mt-2 text-white">LANGUAGE</label>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="audio-voice-box">
                            <div className="form-group text-center">
                                <select onChange={(e) => handleInputText(e)} name="voice" className="form-select">
                                    {
                                        voices.length > 0 ?
                                            voices.map((item, index) => (
                                                <option key={index} value={item.voice_id}>{item.voice_id} ({item?.gender})</option>
                                            ))
                                            : ''
                                    }
                                </select>
                                <label className="mt-2 text-white">VOICE</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="audio-add-text-box">
                            <div className="mb-3">
                                <textarea name="text" className="form-control" placeholder="Add text here" rows={8} onChange={(e) => handleInputText(e)} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row my-2">
                    <div className="col-md-6">
                        <div className="audio-preview-btn">
                            {
                                getStatus === false ?
                                    <button disabled={ttsData.text === '' ? true : false} onClick={() => handleGenTts()} className="form-control  btn-change6 ">
                                        <span className="mr-1">{loader ? <FontAwesomeIcon icon={faSpinner} /> : <FontAwesomeIcon icon={faFileUpload} />}</span> Generate
                                    </button>
                                    :
                                    <button onClick={() => playTts()} className="form-control btn-change6 ">
                                        <span className="mr-1"><FontAwesomeIcon icon={faFileUpload} /></span> Preview
                                    </button>
                            }
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="audio-save-use-btn">
                            <button disabled={getStatus === true ? false : true} onClick={() => handleSaveTts()} className="form-control  btn-change6 ">
                                <span className="mr-1"><FontAwesomeIcon icon={faSave} /></span> Save &amp; Use
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default React.memo(TtsAudio);