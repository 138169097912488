import randomstring from "randomstring";

const initialState = {
    data: {
        version: '3.4.0',
        id: randomstring.generate({ length: 5, charset: 'numeric' }),
        objects: [],
        background: '#000000'
    }
}

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {

        case 'ADD_NEW_LAYERS_DATA':
            return {
                data: {
                    ...state.data,
                    objects: action.payload
                }
            }

        case 'ADD_LAYERS_DATA':
            return {
                data: {
                    ...state.data,
                    objects: [...state.data.objects, payload]
                }
            }

        case 'UPDATE_TIMELINE_SELECTED_LAYER':
            let layerData = state.data.objects.map((item) => {
                return item.is_selected === true ? { ...item, is_selected: false } : { ...item, is_selected: false };
            });
            return {
                ...state,
                data: {
                    ...state.data,
                    objects: layerData
                }
            }

        case 'UPDATE_BACKGROUND_TIMELINE':
            let bgVideo = [...state.data.objects];
            console.log(bgVideo, "bgVideo")
            bgVideo[0].video_src = payload.url;
            bgVideo[0].src = payload.poster;
            return {
                ...state,
                data: {
                    ...state.data,
                    objects: bgVideo
                }
            }

        case 'REMOVE_BACKGROUND_TIMELINE':
            return {
                data: {
                    version: '3.4.0',
                    id: 5348924537,
                    objects: [],
                    background: '#000000'
                }
            }

        case 'ADD_SELECTED_TIMELINE_LAYER':
            let newData = state.data.objects.map((item) => {
                return item.id == payload ? { ...item, is_selected: true } : { ...item, is_selected: false };
            });
            return {
                ...state,
                data: {
                    ...state.data,
                    objects: newData
                }
            }

        case 'REPLACE_TIMELINE_LAYER':
            let newDataTi = state.data.objects.map((item) => {
                return item.id == payload.id ? { ...item, video_src: payload.url } : { ...item, video_src: item.video_src };
            });
            return {
                ...state,
                data: {
                    ...state.data,
                    objects: newDataTi
                }
            }

        case 'UPDATE_TIMELINE_FONT_TEXT':
            let fontText = state.data.objects.map((item) => {
                return item.id == payload.id && item.type === 'textbox' ? { ...item, text: payload.text, width: payload.width, height: payload.height } : { ...item, text: item.text, width: item.width, height: item.height };
            });
            return {
                ...state,
                data: {
                    ...state.data,
                    objects: fontText
                }
            }

        case 'UPDATE_TIMELINE_FONT_FAMILY':
            console.log(state.data.objects, "state.data.objectsstate.data.objects")
            let fontData = state.data.objects.map((item) => {
                return item.is_selected === true && item.type === 'textbox' ? { ...item, fontFamily: payload } : { ...item, fontFamily: item.fontFamily };
            });
            return {
                ...state,
                data: {
                    ...state.data,
                    objects: fontData
                }
            }

        case 'UPDATE_TIMELINE_FONT_SIZE_SCALE':
            let fontSizeScale = state.data.objects.map((item) => {
                return item.id == payload.id && item.type === 'textbox' ? {
                    ...item, fontSize: payload.fontSize, top: payload.top, left: payload.left,
                    scaleX: payload.scaleX, scaleY: payload.scaleY, width: payload.width, height: payload.height
                } : { ...item, fontSize: item.fontSize, top: item.top, left: item.left, scaleX: item.scaleX, scaleY: item.scaleY, width: item.width, height: item.height };
            });
            return {
                ...state,
                data: {
                    ...state.data,
                    objects: fontSizeScale
                }
            }

        case 'UPDATE_TIMELINE_FONT_SIZE':
            let fontSize = state.data.objects.map((item) => {
                return item.is_selected === true && item.type === 'textbox' ? { ...item, fontSize: payload } : { ...item, fontSize: item.fontSize };
            });
            return {
                ...state,
                data: {
                    ...state.data,
                    objects: fontSize
                }
            }

        case 'UPDATE_TIMELINE_FONT_BOLD':
            let fontBold = state.data.objects.map((item) => {
                return item.is_selected === true && item.type === 'textbox' ? { ...item, fontWeight: payload ? 'bold' : 'normal' } : { ...item, fontWeight: item.fontWeight };
            });
            return {
                ...state,
                data: {
                    ...state.data,
                    objects: fontBold
                }
            }

        case 'UPDATE_TIMELINE_FONT_ITALIC':
            let fontItalic = state.data.objects.map((item) => {
                return item.is_selected === true && item.type === 'textbox' ? { ...item, fontStyle: payload ? 'italic' : 'normal' } : { ...item, fontStyle: item.fontStyle };
            });
            return {
                ...state,
                data: {
                    ...state.data,
                    objects: fontItalic
                }
            }

        case 'UPDATE_TIMELINE_FONT_UPPERCASE':
            let fontUpper = state.data.objects.map((item) => {
                return item.is_selected === true && item.type === 'textbox' ? { ...item, fontStyle: payload ? 'uppercase' : 'normal' } : { ...item, fontStyle: item.fontStyle };
            });
            return {
                ...state,
                data: {
                    ...state.data,
                    objects: fontUpper
                }
            }
        case 'UPDATE_TIMELINE_FONT_LOWERCASE':
            let fontLower = state.data.objects.map((item) => {
                return item.is_selected === true && item.type === 'textbox' ? { ...item, fontStyle: payload ? 'lowercase' : 'normal' } : { ...item, fontStyle: item.fontStyle };
            });
            return {
                ...state,
                data: {
                    ...state.data,
                    objects: fontLower
                }
            }

        case 'UPDATE_TIMELINE_FONT_UNDERLINE':
            let fontUnderline = state.data.objects.map((item) => {
                return item.is_selected === true && item.type === 'textbox' ? { ...item, underline: payload } : { ...item, underline: item.underline };
            });
            return {
                ...state,
                data: {
                    ...state.data,
                    objects: fontUnderline
                }
            }

        case 'UPDATE_TIMELINE_FONT_STRIKE':
            let fontStrike = state.data.objects.map((item) => {
                return item.is_selected === true && item.type === 'textbox' ? { ...item, linethrough: payload } : { ...item, linethrough: item.linethrough };
            });
            return {
                ...state,
                data: {
                    ...state.data,
                    objects: fontStrike
                }
            }

        case 'UPDATE_TIMELINE_FONT_ALIGN':
            let fontAlign = state.data.objects.map((item) => {
                return item.is_selected === true && item.type === 'textbox' ? { ...item, textAlign: payload } : { ...item, textAlign: item.textAlign };
            });
            return {
                ...state,
                data: {
                    ...state.data,
                    objects: fontAlign
                }
            }

        case 'UPDATE_TIMELINE_FONT_LINE_HEIGHT':
            let fontLine = state.data.objects.map((item) => {
                return item.is_selected === true && item.type === 'textbox' ? { ...item, lineHeight: payload } : { ...item, lineHeight: item.lineHeight };
            });
            return {
                ...state,
                data: {
                    ...state.data,
                    objects: fontLine
                }
            }


        case 'UPDATE_TIMELINE_FONT_COLOR':
            let fontColor = state.data.objects.map((item) => {
                return item.is_selected === true && item.type === 'textbox' ? { ...item, fill: payload } : { ...item, fill: item.fill };
            });
            return {
                ...state,
                data: {
                    ...state.data,
                    objects: fontColor
                }
            }

        case 'UPDATE_TIMELINE_FONT_BG_COLOR':
            let fontBgColor = state.data.objects.map((item) => {
                return item.is_selected === true && item.type === 'textbox' ? { ...item, textBackgroundColor: payload } : { ...item, textBackgroundColor: item.textBackgroundColor };
            });
            return {
                ...state,
                data: {
                    ...state.data,
                    objects: fontBgColor
                }
            }

        case 'UPDATE_TIMELINE_TOP_LEFT':
            let posArr = state.data.objects.map((item) => {
                return item.id == payload.id ? { ...item, top: payload.top, left: payload.left } : { ...item, top: item.top, left: item.left };
            });
            return {
                ...state,
                data: {
                    ...state.data,
                    objects: posArr
                }
            }

        case 'UPDATE_TIMELINE_IMAGE_OPACITY':
            let imgOp = state.data.objects.map((item) => {
                return item.id == payload.id ? { ...item, opacity: payload.opacity } : { ...item, opacity: item.opacity };
            });
            return {
                ...state,
                data: {
                    ...state.data,
                    objects: imgOp
                }
            }

        case 'UPDATE_TIMELINE_SCALE':
            let scaleArr = state.data.objects.map((item) => {
                return item.id == payload.id ? {
                    ...item,
                    scaleX: payload.scaleX, scaleY: payload.scaleY, top: payload.top, left: payload.left
                } : { ...item, scaleX: item.scaleX, scaleY: item.scaleY, top: item.top, left: item.left };
            });
            return {
                ...state,
                data: {
                    ...state.data,
                    objects: scaleArr
                }
            }

        default:
            return state;
    }
}