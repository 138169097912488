import React, { useState } from "react";
import './css/login-styles.css';
import banner from '../../images/banner-bg.jpg';
import emailIcon from '../../images/email-icon.svg';
import passwordIcon from '../../images/password-icon.svg';

import { Link, useHistory } from 'react-router-dom';
import Footer from "../Footer";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../../actions/authAction";
import Alert from "../Alert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import logo from "../../images/virtualreel-logo.png";
import { appName } from "../../global/global";

const Login = () => {

    const dispatch = useDispatch();
    const brandName = useSelector(state => state.rebrand.data);
    const auth = useSelector(state => state.auth);
    const history = useHistory();
    const rebrandData = useSelector(state => state.rebrand.data);

    const [loader, setLoader] = useState(false);
    const [user, setUser] = useState({
        email: '',
        password: ''
    })

    const handleInputChange = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value });
    }

    const handleFormSubmit = (e) => {
        e.preventDefault();
        setLoader(true);

        dispatch(loginUser(user, setLoader));
    }

    if (auth.isAuthenticated) {
        history.push('/dashboard');
    }

    return (
        <>
            <Helmet>
                <title>{brandName !== false ? brandName.name : appName} | Login </title>
            </Helmet>
            <Alert />
            <div className="login-page-body loGin">
                <main className="login-page-main">
                    <section id="banner" className="banner flex-elem" style={{ background: `url(${banner})` }}>
                        <div className="custom-wrapper container">
                            <div className="row align-items-center justify-content-between">
                                <div className="col-lg-6 col-12 colmn colmn-1">
                                    <div className="title-area2 mb-3">
                                        {/* <h2 className="f-800" style={{color: "#00FF99"}}>WELCOME TO</h2>
                                        <p className="h1 f-700">VirtualReel</p> */}
                                        <div className="dash-logo">
                                            <img
                                                className="img-fluid"
                                                src={rebrandData?.logo || logo}
                                                alt="Logo"
                                                height="auto"
                                            />

                                        </div>
                                        <h2 className="f-300"><span>Welcome To </span>{rebrandData?.name ? rebrandData?.name : appName}!</h2>

                                        {/* <h2 className="f-300">WELCOME TO</h2>
                                        <h1><span className="f-800">Virtual</span>Reel</h1> */}
                                    </div>

                                    <div className="text-wrapper alt">
                                        <p className="md-txt f-300">Effortlessly create 3D Metaverse videos in a few clicks. Turn your regular videos, text, or articles into full-blown 360 Degree VR Videos in any niche.</p>
                                    </div>

                                    {/* <div className="text-wrapper text-center">
                                        <p className="md-txt">Effortlessly create 3D Metaverse videos in a few clicks.
                                            Turn your regular videos, text, or articles into full-blown 360 videos in any
                                            niche.</p>
                                    </div> */}
                                </div>
                                <div className="col-lg-6 col-12 colmn colmn-2">
                                    <h3 className="f-600 mb-4"><span style={{ color: "#00D47F" }}>Login</span> to Your
                                        Account
                                        Now
                                    </h3>

                                    <form method="POST" onSubmit={(e) => handleFormSubmit(e)}>
                                        <div className="form-group">
                                            <label htmlFor="email" className="md-txt text-white">Email Address</label>
                                            <div className="d-flex align-items-center">
                                                <div className="img-wrapper email-icon">
                                                    <img className="img-fluid" src={emailIcon} alt="Email icon" />
                                                </div>

                                                <input required type="email" className="email" name="email" onChange={(e) => handleInputChange(e)} placeholder="Email Address" autoComplete="off" />
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="email" className="md-txt text-white">Password</label>

                                            <div className="d-flex align-items-center">
                                                <div className="img-wrapper email-icon">
                                                    <img className="img-fluid" src={passwordIcon} alt="Email icon" />
                                                </div>

                                                <input required type="password" className="password" name="password" onChange={(e) => handleInputChange(e)} placeholder="*************" autoComplete="off" />
                                            </div>
                                        </div>

                                        <button disabled={loader} type="submit" className="md-txt f-600 cursor-pointer">
                                            {loader ? <FontAwesomeIcon icon={faSpinner} /> : ''} LOGIN TO {rebrandData?.name ? rebrandData?.name : appName}
                                        </button>
                                    </form>

                                    <p className="fs-15 text-center mt-4">Forgot your account details?
                                        <Link to={`/forgot-password`}> Click here</Link>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>

                    <Footer />
                </main>
            </div>
        </>
    )
}


export default Login;