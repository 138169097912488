import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Header from "../Header";
import Footer from "../Footer";
import './css/dashboard-styles.css'
import { useSelector } from "react-redux";
import axios from "axios";
import { appName, baseURL } from "../../global/global";
import ProjectCard from "./ProjectCard";
import { Link } from "react-router-dom";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Projects = () => {

    const brandName = useSelector(state => state.rebrand.data);
    const auth = useSelector(state => state.auth);

    const [loader, setLoader] = useState(false);
    const [projects, setProjects] = useState([]);
    const [q, setQ] = useState('');

    const fetchProjects = () => {
        setLoader(true);
        axios({
            method: 'POST',
            url: `${baseURL}fetch-all-projects`,
            data: {},
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {
            if (res.data.status === true) {
                setProjects(res.data.data);
            } else {
                setProjects([]);
            }

            setLoader(false);
        }).catch(err => {
            setLoader(false);
        })
    }

    const searchCampaign = (e) => {
        setQ(e.target.value)
    }

    const search = (campaigns) => {
        return campaigns.length > 0 ? campaigns.filter(
            campaign => campaign.project_name.toLowerCase().indexOf(q.toLowerCase()) > -1
        ) : []
    }


    useEffect(() => {
        fetchProjects();

        return () => {
            setProjects([]);
        }
    }, [])


    return (
        <>
            <Helmet>
                <title>{brandName !== false ? brandName.name : appName} | Projects </title>
            </Helmet>

            <div className="meta-lib-page-body">
                <div className="meta-lib-page-main">
                    <Header />

                    <section id="s1" className="s1">
                        <div className="custom-wrapper custom-wrapper-pro container">
                            <div className="my-video-projects-header mt-3 mb-5">
                                <div className="row d-flex justify-content-center align-items-center">
                                    <div className="col-12 col-md-5  d-flex justify-content-lg-start justify-content-center">
                                        <div className="project-header d-flex">
                                            <h4 className="project-title">ALL PROJECTS</h4>
                                            <h6 className="project-count">{search(projects).length} Project</h6>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-7  d-flex justify-content-lg-end justify-content-center">
                                        <div className="project-button d-flex">
                                            <div className="project-button-search">
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text" id="basic-addon1">
                                                            <FontAwesomeIcon icon={faSearch} />
                                                        </span>
                                                    </div>
                                                    <input onChange={(e) => searchCampaign(e)} type="text" className="form-control search_thumb" placeholder="Search Videos" />
                                                </div>
                                            </div>
                                            {/*{*/}
                                            {/*    auth.user.is_client_account === "0" ?*/}
                                            {/*        <div className="project-button-create">*/}
                                            {/*            <Link to="/" className="gradiant-button-pb create-playlist-btn">*/}
                                            {/*                <FontAwesomeIcon icon={faPlusCircle}/> Create Videos*/}
                                            {/*            </Link>*/}
                                            {/*        </div>*/}
                                            {/*    : ''*/}
                                            {/*}*/}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="my-video-projects-card projects row mx-0">
                                {
                                    projects.length > 0 ?
                                        search(projects).map((item, index) => (
                                            <ProjectCard item={item} fetchProjects={fetchProjects} key={item.project_id} />
                                        ))
                                        : ''
                                }

                                {
                                    loader === false && projects.length === 0 && q === '' ?
                                        <h6 className="text-center">You have not created any 360 Degree Video yet. Start creating a stunning videos from your dashboard now.</h6>
                                        : ''
                                }
                                {
                                    loader ? <h4 className="text-center">Fetching projects...</h4> : ''
                                }
                                {
                                    search(projects).length === 0 && loader === false && q !== '' ?
                                        <h4 className="text-center">No Projects found for this '{q}' name</h4>
                                        : ''
                                }
                            </div>
                        </div>
                    </section>

                    <Footer />
                </div>
            </div>
        </>
    )
}

export default React.memo(Projects);