import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Header from "../Header";
import Footer from "../Footer";
import './css/update-style.css'
import UpgradeRebrand from "./UpgradeRebrand";

import wordpressIcon from '../../images/wordpress.png';
import importIcon from "../../images/import.png";
import customizeIcon from "../../images/customize.png";
import downloadIcon from "../../images/download.png";
import modelIcon from "../../images/modal-img.jpg";
import { useSelector } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons"
import { appName } from "../../global/global";

const Upgrades = () => {

    const brandName = useSelector(state => state.rebrand.data);
    const auth = useSelector(state => state.auth);

    const [showModel, setShowModel] = useState(false);
    const [memberships, setMemberships] = useState([]);


    /* For Check Membership Levels */
    // useEffect(() => {
    //     if (auth.user !== null) {

    //         let memLevels = auth?.user?.membership.split('__');
    //         setMemberships(memLevels);

    //     }
    // }, [auth.user])
    useEffect(() => {

        let mem = auth?.user?.membership
        if (mem) {
            let memLevels = mem?.split('__');
            setMemberships(memLevels);
        } else {
            setMemberships([]);
            console.log("No Membership")
        }

    }, [auth.user])
    return (
        <>
            <Helmet>
                <title>{brandName !== false ? brandName.name : appName} | Upgrades </title>
            </Helmet>

            <div className="update-body-page">
                <main className="update-main-body">
                    <Header />

                    <section className="update">
                        <div className="custom-wrap">
                            <div className="sec-row row justify-content-between">
                                <div className="col-md-4 colmn colmn-1">
                                    <div className="sidebar">
                                        <div className="topics">
                                            <div className="topic-sub">
                                                <div className="list-group" id="list-tab" role="tablist">
                                                    {
                                                        memberships.includes('business') ?
                                                            <>
                                                                <a className="fs-18 list-group-item list-group-item-action"
                                                                    id="list-rebranding-list" data-toggle="list"
                                                                    href="#list-rebranding" role="tab"
                                                                    aria-controls="agency-rebranding">
                                                                    Agency Rebranding
                                                                </a>
                                                                <a className="fs-18 list-group-item list-group-item-action" id="list-license-list" data-toggle="list" href="#list-license" role="tab" aria-controls="reseller-license">
                                                                    Reseller License
                                                                </a>
                                                                <a className="fs-18 list-group-item list-group-item-action" id="list-client-list" data-toggle="list" href="#list-client" role="tab" aria-controls="client-contract">
                                                                    Client Contract
                                                                </a>
                                                                <a className="fs-18 list-group-item list-group-item-action" id="list-website-list" data-toggle="list" href="#list-website" role="tab" aria-controls="agency-website">
                                                                    Agency Website
                                                                </a>
                                                            </>
                                                            : ''
                                                    }
                                                    {/*<a className="fs-18 list-group-item list-group-item-action" id="list-finder-list" data-toggle="list" href="#list-finder" role="tab" aria-controls="business-finder">*/}
                                                    {/*    Business Finder*/}
                                                    {/*</a>*/}
                                                    <a className="fs-18 list-group-item list-group-item-action" id="list-templates-list" data-toggle="list" href="#list-templates" role="tab" aria-controls="fb-templates">
                                                        FB Templates
                                                    </a>

                                                    {
                                                        memberships.includes('unlimited') ?
                                                            <a className="fs-18 list-group-item list-group-item-action"
                                                                id="list-acadeable-list" data-toggle="list"
                                                                href="#list-acadeable" role="tab" aria-controls="acadeable">
                                                                Acadeable
                                                            </a>
                                                            : ''
                                                    }

                                                    <a className="fs-18 list-group-item list-group-item-action" id="list-hosting-list" data-toggle="list" href="#list-hosting" role="tab" aria-controls="web-hosting">
                                                        Web Hosting
                                                    </a>
                                                    <a className="fs-18 list-group-item list-group-item-action" id="list-commercial-list" data-toggle="list" href="#list-commercial" role="tab" aria-controls="video-commercial">
                                                        Video Commercial
                                                    </a>
                                                    <a className="fs-18 list-group-item list-group-item-action" id="list-dfy-list" data-toggle="list" href="#list-dfy" role="tab" aria-controls="dfy-lead-magnets">
                                                        DFY Lead Magnets
                                                    </a>

                                                    {
                                                        memberships.includes('professional') ?
                                                            <a className="fs-18 list-group-item list-group-item-action"
                                                                id="list-dfy-assets-list" data-toggle="list"
                                                                href="#list-dfy-assets" role="tab"
                                                                aria-controls="dfy-lead-assets">
                                                                DFY Social Media Assets
                                                            </a>
                                                            : ''
                                                    }
                                                    {/*<a className="fs-18 list-group-item list-group-item-action" id="media-asset" data-toggle="list" href="#media-asset" role="tab" aria-controls="media-asset">*/}
                                                    {/*    Social Media Assets*/}
                                                    {/*</a>*/}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-8 colmn colmn-2">
                                    <div className="tab-content" id="nav-tabContent">
                                        <div className="tab-pane fade show active" role="tabpanel">
                                            <div className="tab-wrapper">
                                                <div className="tab-header">
                                                    <p className="fs-18">Welcome</p>
                                                </div>
                                                <div className="tab-desc">
                                                    <p className="fs-18 text-center">To access your upgrades, click any of the available options on the left side menu.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade list-rebranding" id="list-rebranding" role="tabpanel" aria-labelledby="list-rebranding-list">
                                            <UpgradeRebrand />
                                        </div>
                                        {/*<div className="tab-pane fade list-finder" id="list-finder" role="tabpanel" aria-labelledby="list-finder-list">*/}
                                        {/*    <div className="tab-wrapper">*/}
                                        {/*        <div className="tab-header">*/}
                                        {/*            <p className="fs-18">Business Finder</p>*/}
                                        {/*        </div>*/}
                                        {/*        <div className="tab-desc">*/}
                                        {/*            <iframe src="https://resources.vega6.info/business-finder?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJpYXQiOjE2MDYxMjUyMjcsImp0aSI6IldZY3gwQW42cnFHcHdhRUluYlNWTWwiLCJpc3MiOiJodHRwczpcL1wvcmVzb3VyY2VzLnZlZ2E2LmluZm9cLyIsIm5iZiI6MTYwNjEyNTIzNywiZGF0YSI6eyJ1c2VyX2lkIjoiMSIsImFwcF91cmwiOiJodHRwczpcL1wvdjJzZXJ2ZXIudmlkZW9yZWVsLmlvXC8ifX0.fN5JpYG7hFfXgprbTLyGbtbBE-T4-8SG25xtn4ckOkGmwevJEANb2UjngHIaUiAVNNmzVIeLotsVRZb9aB9g_Q" style={{width: '100%', height: '100vh'}} />*/}
                                        {/*        </div>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                        <div className="tab-pane fade list-templates" id="list-templates" role="tabpanel" aria-labelledby="list-templates-list">
                                            <div className="tab-wrapper">
                                                <div className="tab-header">
                                                    <p className="fs-18">Facebook Ads Templates</p>
                                                </div>
                                                <div className="tab-desc">
                                                    <p className="fs-18 text-center">Download this done-for-you Facebook and Instagram ad creatives and ad copy templates that can easily be customized to be used for any niche</p>
                                                    <div className="box-group mt-3">
                                                        <p className="fs-18 text-center">Download your Facebook Ads Templates here</p>
                                                        <a target="_blank" href="https://s3-us-west-2.amazonaws.com/adsviser3/library/adpack1.zip" className="text-white btn-green-blue">Download the Templates</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade list-acadeable" id="list-acadeable" role="tabpanel" aria-labelledby="list-acadeable-list">
                                            <div className="tab-wrapper">
                                                <div className="tab-header">
                                                    <p className="fs-18">Acadeable</p>
                                                </div>
                                                <div className="tab-desc">
                                                    <p className="fs-18 text-center">Download Udemy Style Website to showcase your courses. Instant Access to Udemy Style Website Builder to Sell Courses</p>
                                                    <div className="box-wrapper mt-3 mb-4">
                                                        <a target="_blank" href="https://acadeable.s3.amazonaws.com/Acadeable_files.zip" className="text-white mt-0">Download Setup</a>
                                                    </div>
                                                    <p className="fs-18 mb-3">Tutorials</p>
                                                    <div className="acadeable-tutorials">
                                                        <div className="box-wrapper">
                                                            <a target="_blank" href="https://misctrainingvideos.s3.amazonaws.com/Acadeable/How+to+install+%26+setup+Acadeable.mp4" className="text-white">How to install &amp; setup Acadeable</a>
                                                        </div>
                                                        <div className="box-wrapper">
                                                            <a target="_blank" href="https://misctrainingvideos.s3.amazonaws.com/Acadeable/Installation+with+Apache.pdf" className="text-white">Installation with Apache</a>
                                                        </div>
                                                        <div className="box-wrapper">
                                                            <a href="https://misctrainingvideos.s3.amazonaws.com/Acadeable/Installing+Acedeable.pdf" target="_blank" className="text-white">Installing Acadeable</a>
                                                        </div>
                                                        <div className="box-wrapper">
                                                            <a href="https://misctrainingvideos.s3.amazonaws.com/Acadeable/PWA+Setup.pdf" target="_blank" className="text-white">PWA Setup</a>
                                                        </div>
                                                        <div className="box-wrapper">
                                                            <a href="https://misctrainingvideos.s3.amazonaws.com/Acadeable/Server+Requirements.pdf" target="_blank" className="text-white">Server Requirements</a>
                                                        </div>
                                                        <div className="box-wrapper">
                                                            <a href="https://misctrainingvideos.s3.amazonaws.com/Acadeable/Shared+Hosting+Install.pdf" target="_blank" className="text-white">Shared Hosting Install</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade list-client" id="list-client" role="tabpanel" aria-labelledby="list-client-list">
                                            <div className="tab-wrapper">
                                                <div className="tab-header">
                                                    <p className="fs-18">Client Contract</p>
                                                </div>
                                                <div className="tab-desc">
                                                    <p className="fs-18 text-center">Download our "Client Contract" template. This is a docx file, works best with MS Word. Edit the doc and add your business name, address and other details. Fill the empty blank space with your service, eg Video Marketing, Local Reputation Management, Social Media Marketing, Design &amp; Creatives, Ads Management and so on.</p>
                                                    <div className="row mt-4">
                                                        <div className="col-md-4 col-12 cc-colmn cc-colmn-1">
                                                            <div className="col-header">
                                                                <p className="f-500">Brief Pitch Template1_2</p>
                                                            </div>
                                                            <div className="box-wrapper">
                                                                <a href="https://misctrainingvideos.s3.amazonaws.com/Client+Contract/Client-Brief-Form.doc" target="_blank" className="text-white">Download</a>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4 col-12 cc-colmn cc-colmn-2">
                                                            <div className="col-header">
                                                                <p className="f-500">Service Agreement</p>
                                                            </div>
                                                            <div className="box-wrapper">
                                                                <a href="https://misctrainingvideos.s3.amazonaws.com/Client+Contract/Services+Agreement.doc" target="_blank" className="text-white">Download</a>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4 col-12 cc-colmn cc-colmn-3">
                                                            <div className="col-header">
                                                                <p className="f-500">Meeting Setup Template1_2</p>
                                                            </div>
                                                            <div className="box-wrapper">
                                                                <a href="https://misctrainingvideos.s3.amazonaws.com/Client+Contract/Meeting-Doc.doc" target="_blank" className="text-white">Download</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade list-license" id="list-license" role="tabpanel" aria-labelledby="list-license-list">
                                            <div className="tab-wrapper">
                                                <div className="tab-header">
                                                    <p className="fs-18">Reseller License</p>
                                                </div>
                                                <div className="tab-desc">
                                                    <p className="fs-18 text-center">Go to JVZoo Dashboard and request your affiliate link. Once you've requested, submit a ticket with your VirtualReel Business transaction ID, affiliate ID and email address for our team to activate your reseller license. Please allow upto 2 working days.</p>
                                                    <div className="row mx-0 justify-content-between mt-3">
                                                        <div className="box-wrapper col-md-6 col-12">
                                                            <a href="https://www.jvzoo.com/affiliate/affiliateinfo/index/376849" target="_blank" className="text-white">Request Affiliate Link</a>
                                                        </div>
                                                        <div className="box-wrapper col-md-6 col-12">
                                                            <a href="https://support.vineasx.com/" target="_blank" className="text-white">Raise Support Ticket</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade list-hosting" id="list-hosting" role="tabpanel" aria-labelledby="list-hosting-list">
                                            <div className="tab-wrapper">
                                                <div className="tab-header">
                                                    <p className="fs-18">Web Hosting</p>
                                                </div>
                                                <div className="tab-desc">
                                                    <p className="fs-18 text-center">Please raise a support ticket with your VirtualReel purchase transaction ID, your domain name that you currently own and wish to use with the hosting, email address, your full name and a note about how you would be using the webhosting for your business.</p>
                                                    <div className="box-wrapper mt-3">
                                                        <a href="https://support.vineasx.com/" target="_blank" className="text-white mt-0">Raise a Ticket</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade list-commercial" id="list-commercial" role="tabpanel" aria-labelledby="list-commercial-list">
                                            <div className="tab-wrapper">
                                                <div className="tab-header">
                                                    <p className="fs-18">Video Commercial</p>
                                                </div>
                                                <div className="tab-desc">
                                                    <p className="fs-18 text-center">6X Stunning &amp; Compelling Video Commercials</p>
                                                    <div className="box-wrapper mt-3">
                                                        <a href="https://abhibonus.s3-us-west-2.amazonaws.com/6X+Stunning+%26+Compelling+Video+Commercials+.zip" target="_blank" className="text-white mt-0">Download Videos</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade list-dfy" id="list-dfy" role="tabpanel" aria-labelledby="list-dfy-list">
                                            <div className="tab-wrapper">
                                                <div className="tab-header">
                                                    <p className="fs-18">DFY Lead Magnets</p>
                                                </div>
                                                <div className="tab-desc">
                                                    <div className="box-wrapper mt-3">
                                                        <a href="https://s3-us-west-2.amazonaws.com/virtualpioneer.net/Sendiio/100.zip" target="_blank" className="text-white mt-0">Download Videos</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade list-dfy-assets" id="list-dfy-assets" role="tabpanel" aria-labelledby="list-dfy-assets-list">
                                            <div className="tab-wrapper">
                                                <div className="tab-header">
                                                    <p className="fs-18">DFY Social Media Assets</p>
                                                </div>
                                                <div className="tab-desc">
                                                    <p className="fs-18 text-center">Download your plug & play social media assets bellow</p>
                                                    <div className="box-wrapper mt-3 mb-4" />
                                                    <div className="acadeable-tutorials">
                                                        <div className="box-wrapper">
                                                            <a target="_blank" href="https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/Visual+Assets/800+Motivational+Videos.zip" className="text-white">Social Media Video Posts</a>
                                                        </div>
                                                        <div className="box-wrapper">
                                                            <a target="_blank" href="https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/Visual+Assets/6900%2B+Facts+Images.zip" className="text-white">Social Media Image Posts</a>
                                                        </div>
                                                        <div className="box-wrapper">
                                                            <a href="https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/Visual+Assets/Captions.xlsx" target="_blank" className="text-white">Social Media Captions in 100+ Niches</a>
                                                        </div>
                                                        <div className="box-wrapper">
                                                            <a href="https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/Visual+Assets/3565TopQuotePics.zip" target="_blank" className="text-white">Inspirational, Motivational Quotes Images</a>
                                                        </div>
                                                        <div className="box-wrapper">
                                                            <a href="https://abhibonus.s3-us-west-2.amazonaws.com/upgradebonus/Visual+Assets/180K-famous-quotations5RR.zip" target="_blank" className="text-white">Library of Quotes To Use in Social Media</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade list-website" id="list-website" role="tabpanel" aria-labelledby="list-website-list">
                                            <div className="tab-wrapper">
                                                <div className="tab-header">
                                                    <p className="fs-18">Agency Website</p>
                                                </div>
                                                <div className="tab-desc">
                                                    <div className="box-wrapper">
                                                        <div className="img-wrapper">
                                                            <img className="img-fluid" src={wordpressIcon} alt="wordpress" />
                                                        </div>
                                                        <div className="text-wrapper">
                                                            <p>Fresh Install and configure wordpress at your server. If you need help in installing and configuring wordpress, Click here to find the tutorial.</p>
                                                        </div>
                                                    </div>
                                                    <div className="box-wrapper">
                                                        <div className="img-wrapper">
                                                            <img className="img-fluid" src={downloadIcon} alt="download" />
                                                        </div>
                                                        <div className="text-wrapper">
                                                            <p>Once wordpress is installed at your server. Download the site content by clicking the "Download site button". This will download a wpress file which you will be needing to import in your wordpress site.</p>
                                                            <a target="_blank" href="https://misctrainingvideos.s3.amazonaws.com/Agency+Website/AgencyWebsite_new.wpress" className="text-white mt-0">Download the site</a>
                                                        </div>
                                                    </div>
                                                    <div className="box-wrapper">
                                                        <div className="img-wrapper">
                                                            <img className="img-fluid" src={importIcon} alt="import" />
                                                        </div>
                                                        <div className="text-wrapper">
                                                            <p>Import the xml file in to your wordpress site. If you need help importing the xml file in wordpress, Click here to find the tutorial.</p>
                                                        </div>
                                                    </div>
                                                    <div className="box-wrapper">
                                                        <div className="img-wrapper">
                                                            <img className="img-fluid" src={customizeIcon} alt="customize" />
                                                        </div>
                                                        <div className="text-wrapper">
                                                            <p>Customize the site as according to your need. If you need help customizing the site. Click here to find the tutorial</p>
                                                        </div>
                                                    </div>
                                                    <div className="webite-tutorial">
                                                        <div className="row mx-0">
                                                            <div className="col-md-6 col-12 colmn colmn-1">
                                                                <div className="d-flex flex-wrap align-items-center">
                                                                    <div className="img-wrapper pr-3">
                                                                        <img className="img-fluid" src={wordpressIcon} alt="wordpress" />
                                                                    </div>
                                                                    <div className="web-tutorial-btn">
                                                                        <a target="_blank" href="https://misctrainingvideos.s3.amazonaws.com/Agency+Website/Install+Wordpress+in+cPanel.pdf" className="text-white">Install WordPress From cPanel</a>
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex flex-wrap align-items-center">
                                                                    <div className="img-wrapper pr-3">
                                                                        <img className="img-fluid" src={importIcon} alt="import" />
                                                                    </div>
                                                                    <div className="web-tutorial-btn">
                                                                        <a target="_blank" href="https://misctrainingvideos.s3.amazonaws.com/Agency+Website/Import+Agency+Data.pdf" className="text-white">Import SyVid VirtualReel</a>
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex flex-wrap align-items-center">
                                                                    <div className="img-wrapper pr-3">
                                                                        <img className="img-fluid" src={customizeIcon} alt="customize" />
                                                                    </div>
                                                                    <div className="web-tutorial-btn">
                                                                        <a target="_blank" href="https://misctrainingvideos.s3.amazonaws.com/Agency+Website/Customize+Agency+Website.pdf" className="text-white">Customize Website</a>
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex flex-wrap align-items-center">
                                                                    <div className="img-wrapper pr-3">
                                                                        <img className="img-fluid" src={downloadIcon} alt="download" />
                                                                    </div>
                                                                    <div className="web-tutorial-btn">
                                                                        <a target="_blank" href="https://misctrainingvideos.s3.amazonaws.com/Agency+Website/Upload+Video+and+Usage+in+Agency+Website.pdf" className="text-white">Upload Video and Usage in VirtualReel Website</a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6 col-12 colmn colmn-2">
                                                                <div className="card fb-card border-0">
                                                                    <div className="card-header text-center" style={{ background: 'rgb(0, 123, 255)' }}>
                                                                        <h6 className="text-white Account-Management-headings">How to Install the Agency Website</h6>
                                                                    </div>
                                                                    <div className="card-body p-0">
                                                                        <img src={modelIcon} alt="model" className="img-fluid" />
                                                                        <span className="youtubeicon" onClick={() => setShowModel(true)} style={{ cursor: 'pointer', color: 'rgb(0, 123, 255)' }}>
                                                                            <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="play-circle" width={30} role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="svg-inline--fa fa-play-circle fa-w-16 fa-2x"><path fill="currentColor" d="M371.7 238l-176-107c-15.8-8.8-35.7 2.5-35.7 21v208c0 18.4 19.8 29.8 35.7 21l176-101c16.4-9.1 16.4-32.8 0-42zM504 256C504 119 393 8 256 8S8 119 8 256s111 248 248 248 248-111 248-248zm-448 0c0-110.5 89.5-200 200-200s200 89.5 200 200-89.5 200-200 200S56 366.5 56 256z" className /></svg>
                                                                        </span>
                                                                    </div>
                                                                </div>

                                                                <div className={`modal fade demo-modal ${showModel ? 'show-drop show' : ''}`} role="dialog"
                                                                    aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                                    <div className="modal-dialog" role="document">
                                                                        <div className="modal-content">
                                                                            <div className="modal-header">
                                                                                <h5 className="modal-title"></h5>
                                                                                <button onClick={() => setShowModel(false)} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                                                    {/* <span aria-hidden="true">&times;</span> */}
                                                                                    <span aria-hidden="true">
                                                                                        <FontAwesomeIcon icon={faTimesCircle} />
                                                                                    </span>
                                                                                </button>
                                                                            </div>

                                                                            <div className="modal-body">
                                                                                <div class='embed-container'>
                                                                                    <iframe id="frameSrc" className="embed-responsive-item"
                                                                                        src="https://player.vimeo.com/video/514577280?"></iframe>
                                                                                    {/* <iframe src={vidData.url} frameborder='0' webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe> */}
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>



                                        <div className="tab-pane fade list-website" id="list-website" role="tabpanel" aria-labelledby="list-website-list">
                                            <div className="tab-wrapper">
                                                <div className="tab-header">
                                                    <p className="fs-18">Social Media Assets</p>
                                                </div>
                                                <div className="tab-desc">
                                                    <div className="box-wrapper">
                                                        <div className="img-wrapper">
                                                            <img className="img-fluid" src={wordpressIcon} alt="wordpress" />
                                                        </div>
                                                        <div className="text-wrapper">
                                                            <p>Fresh Install and configure wordpress at your server. If you need help in installing and configuring wordpress, Click here to find the tutorial.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <Footer />

                </main>
            </div>
        </>
    )
}

export default React.memo(Upgrades);