import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {baseURL} from "../../global/global";
import {setAlert} from "../../actions/alert";
import {useHistory} from "react-router-dom";
import Alert from "../Alert";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSave, faCamera, faSpinner, faTimesCircle, faEye} from "@fortawesome/free-solid-svg-icons"
import {Pannellum} from "pannellum-react";


const EditorHead = ({canvasImgData, handleSave}) => {

    const [showModal, setShowModal] = useState(false);
    const [previewStatus, setPreviewStatus] = useState(false);
    const [previewImage, setPreviewImage] = useState('');

    const auth = useSelector(state => state.auth);
    const campaignData = useSelector(state => state.currentLayer.data);
    const timelineData = useSelector(state => state.timeline.data);
    const dispatch = useDispatch();
    const history = useHistory();

    const [saveLoader, setSaveLoader] = useState(false);
    const [renderLoader, setRenderLoader] = useState(false);

    const [campaignName, setCampaignName] = useState(campaignData.project_name);

    const getVideoDuration = async (url, type) => {
        return new Promise((resolve, reject) => {

            if (url.includes('www.youtube.com')) {
                resolve(campaignData.duration)
            }

            const newVideo = document.createElement('video');
            newVideo.preload = 'metadata'
            newVideo.src = url;
            newVideo.onloadedmetadata = (data) => {
                newVideo.remove()

                if (type === 'bg_file') {
                    if (parseInt(campaignData.duration) < newVideo.duration.toFixed(0)) {
                        if (campaignData.duration == 0) {
                            resolve(newVideo.duration.toFixed(0))
                        } else {
                            resolve(campaignData.duration)
                        }
                    } else {
                        resolve(newVideo.duration.toFixed(0))
                    }
                } else {
                    if(type === 'intro' || type === 'outro'){
                        resolve(newVideo.duration.toFixed(0))
                    }else{
                        resolve(campaignData.duration)
                    }
                }


            };

            newVideo.onerror = () => {
                reject('Unable to load video')
            }
        })
    }

    const handleSaveCampaign = async (type) => {

        if (type === 'save_project') {
            setSaveLoader(true);
            handleSave();
        } else {
            setRenderLoader(true);
        }

        let introStart = 0, introEnd = 0;
        let bgEnd = 0;

        campaignData.project_name = campaignName;
        campaignData.user_id = auth.user?.id;
        campaignData.type = type;

        if (campaignData.intro_video.length > 0) {
            await getVideoDuration(campaignData.intro_video[0].path, 'intro').then(res => {
                campaignData.intro_video[0].startTime = parseInt(introStart);
                campaignData.intro_video[0].endTime = parseInt(res);
                introEnd = res;
            }).catch(err => dispatch(setAlert(err, 'danger')));
        }

        if (campaignData.bg_file.length > 0) {
            let filePath = campaignData.bg_file[0].path;

            if (filePath.includes('www.youtube.com') || filePath.includes('.mp4')) {
                await getVideoDuration(campaignData.bg_file[0].path, 'bg_file').then(res => {
                    campaignData.bg_file[0].startTime = parseInt(introEnd);
                    campaignData.bg_file[0].endTime = parseInt(introEnd) + parseInt(res);
                    bgEnd = parseInt(introEnd) + parseInt(res);

                    /* update text or image layer */
                    if (campaignData.texts_data.length > 0) {
                        campaignData.texts_data = campaignData.texts_data.map((text) => ({
                            ...text,
                            startTime: parseInt(introEnd),
                            endTime: parseInt(introEnd) + parseInt(res)
                        }))

                    }

                    if (campaignData.images_data.length > 0) {
                        campaignData.images_data = campaignData.images_data.map((image) => ({
                            ...image,
                            startTime: parseInt(introEnd),
                            endTime: parseInt(introEnd) + parseInt(res)
                        }))
                    }

                }).catch(err => dispatch(setAlert(err, 'danger')));
            } else {
                let res = campaignData.duration;

                campaignData.bg_file[0].startTime = parseInt(introEnd);
                campaignData.bg_file[0].endTime = parseInt(introEnd) + parseInt(res);
                bgEnd = parseInt(introEnd) + parseInt(res);

                /* update text or image layer */
                if (campaignData.texts_data.length > 0) {
                    campaignData.texts_data = campaignData.texts_data.map((text) => ({
                        ...text,
                        startTime: parseInt(introEnd),
                        endTime: parseInt(introEnd) + parseInt(res)
                    }))
                }

                if (campaignData.images_data.length > 0) {
                    campaignData.images_data = campaignData.images_data.map((image) => ({
                        ...image,
                        startTime: parseInt(introEnd),
                        endTime: parseInt(introEnd) + parseInt(res)
                    }))
                }
            }

        }

        if (campaignData.outro_video.length > 0) {
            await getVideoDuration(campaignData.outro_video[0].path, 'outro').then(res => {
                campaignData.outro_video[0].startTime = parseInt(bgEnd);
                campaignData.outro_video[0].endTime = parseInt(bgEnd) + parseInt(res);
            }).catch(err => dispatch(setAlert(err, 'danger')));
        }

        campaignData.layers = timelineData.objects;

        axios({
            method: 'POST',
            url: `${baseURL}save-project`,
            data: campaignData,
            headers: {'Content-Type': 'application/json', 'Authorization': auth.token}
        }).then(res => {
            if (res.data.status === true) {

                if (type === 'save_project') {
                    dispatch(setAlert(res.data.message, 'success'))
                } else {
                    dispatch(setAlert(res.data.message, 'success'))
                    history.push('/dashboard');
                }

            } else {
                dispatch(setAlert(res.data.message, 'danger'))
            }
            setSaveLoader(false);
            setRenderLoader(false);
        }).catch(err => {
            setSaveLoader(false);
            setRenderLoader(false);
            dispatch(setAlert('Something went wrong, please try again', 'danger'))
        })
    }

    const previewData = async () => {
        setShowModal(true);
        setPreviewStatus(true);

        setPreviewImage(canvasImgData);

    }

    return (
        <>
            <Alert/>
            <div className="row m-0">
                <div className="col-md-12 d-flex flex-wrap align-items-center justify-content-center justify-content-lg-end gap-2">
                    <div className="add-campaign">
                        <input type="text" onChange={(e) => setCampaignName(e.target.value)}
                               defaultValue={campaignData.project_name} className="form-control"
                               placeholder="Add Campaign Name"/>
                    </div>
                    {
                        campaignData.campaign_type!=='campaign_two'  ?
                            <div className="preview-projectbtn">
                                <button onClick={() => previewData()} type="button" className="btn-change6">
                                    <span className="mr-1"><FontAwesomeIcon icon={faEye}/></span> Preview
                                </button>
                            </div>
                        : ''
                    }
                    <div className="save-projectbtn">
                        <button disabled={campaignName === '' ? true : false}
                                onClick={() => handleSaveCampaign('save_project')} type="button"
                                className="btn-change6">
                            <span className="mr-1"><FontAwesomeIcon icon={saveLoader ? faSpinner : faSave}/></span> Save
                        </button>
                    </div>
                    <div className="render-projectbtn">
                        <button disabled={campaignName === '' ? true : false}
                                onClick={() => handleSaveCampaign('render_project')} type="button"
                                className="btn-change6">
                            <span className="mr-1"><FontAwesomeIcon
                                icon={renderLoader ? faSpinner : faCamera}/></span> Render
                        </button>
                    </div>
                </div>
            </div>

            <div className={`modal fade demo-modal popular-videos  ${showModal ? 'show-drop' : ''}`} id="demoModal" tabIndex="-1" role="dialog"
                 aria-labelledby="demoModal" aria-hidden="true" style={{opacity: "1"}}>
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Preview</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setShowModal(false)}>
                                <span aria-hidden="true">
                                    <FontAwesomeIcon icon={faTimesCircle}/>
                                </span>
                            </button>
                        </div>
                        <div id="pano-image" className="modal-body" style={{paddingTop: '5px'}}>
                            <p style={{fontSize: "13px", marginBottom: '10px'}}>The preview you see does not include animation and is not the exact video but a screenshot/instance of the VR Video that will render.</p>
                            <Pannellum
                                width="100%"
                                height="400px"
                                image={ previewImage}
                                pitch={10}
                                yaw={180}
                                hfov={110}
                                autoLoad
                                showZoomCtrl={false}
                                onLoad={() => {
                                    console.log("panorama loaded");
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default React.memo(EditorHead);