export const colorObj = [
    {
        val: 'rgba(255, 255, 255, 1)',
        r: 255,
        g: 255,
        b: 255,
    },
    {
        val: 'rgba(225, 225, 225, 1)',
        r: 225,
        g: 225,
        b: 225,
    },
    {
        val: 'rgba(215, 215, 215, 1)',
        r: 215,
        g: 215,
        b: 215,
    },
    {
        val: 'rgba(204, 204, 204, 1)',
        r: 204,
        g: 204,
        b: 204,
    },
    {
        val: 'rgba(194, 194, 194, 1)',
        r: 194,
        g: 194,
        b: 194,

    },
    {
        val: 'rgba(183, 183, 183, 1)',
        r: 183,
        g: 183,
        b: 183,
    },
    {
        val: 'rgba(161, 161, 161, 1)',
        r: 161,
        g: 161,
        b: 161,
    },
    {
        val: 'rgba(149, 149, 149, 1)',
        r: 149,
        g: 149,
        b: 149,
    },
    {
        val: 'rgba(232, 201, 255, 1)',
        r: 232,
        g: 201,
        b: 255,
    },
    {
        val: 'rgba(200, 126, 255, 1)',
        r: 200,
        g: 126,
        b: 255,
    },
    {
        val: 'rgba(243, 74, 255, 1)',
        r: 243,
        g: 74,
        b: 255,
    },
    {
        val: 'rgba(209, 157, 248, 1)',
        r: 209,
        g: 157,
        b: 248,
    },
    {
        val: 'rgba(155, 51, 233, 1)',
        r: 155,
        g: 51,
        b: 233,
    },
    {
        val: 'rgba(129, 51, 219, 1)',
        r: 129,
        g: 51,
        b: 219,
    },
    {
        val: 'rgba(112, 51, 213, 1)',
        r: 112,
        g: 51,
        b: 213,
    },
    {
        val: 'rgba(35, 128, 63, 1)',
        r: 35,
        g: 128,
        b: 63,
    },
    {
        val: 'rgba(84, 196, 35, 1)',
        r: 84,
        g: 196,
        b: 35,
    },
    {
        val: 'rgba(96, 104, 136, 1)',
        r: 96,
        g: 104,
        b: 136,
    },
    {
        val: 'rgba(124, 131, 172, 1)',
        r: 124,
        g: 131,
        b: 172,
    },
    {
        val: 'rgba(91, 105, 190, 1)',
        r: 91,
        g: 105,
        b: 190,
    },
    {
        val: 'rgba(48, 64, 155, 1)',
        r: 48,
        g: 64,
        b: 155,
    },
    {
        val: 'rgba(34, 60, 219, 1)',
        r: 34,
        g: 60,
        b: 219,
    }
]

export const fontNotSet = ["Helvetica", "Bebas", "palatino linotype", "Subjectivity", "LEMON MILK", "Gill Sans Ultra Bold", "Gilroy", "MS Reference Sans Serif", "MS Serif"
    , "Microsoft JhengHei", "Times New Roman", "MS UI Gothic", "Bookman Old Style", "Franklin Gothic Book", "Gill Sans MT", "Eras Light ITC", "Microsoft Sans Serif", "Microsoft YaHei UI", "Helvetica Now Display", "Gadugi", "Tw Cen MT"
    , "Ebrima", "Subjectivity Serif", "Clarendon", "Segoe UI Emoji", "Fashion Fetish Heavy", "Bastian Sidney", "Palatino Linotype", "Integral CF", "Steelfish", "Monument Extended", "Metropolis", "SignPainter HouseScript", "ChunkFive", "Franklin Gothic", "Agency FB", "Marchelina Script", "Franklin Gothic Medium", "Nexa Bold", "Spartwell", "Spartwell"
    , "Akira Expanded", "Calibri", "Zeyada", "Droid Serif", "Droid Sans Mono", "Droid Sans"]