import { commonAxios } from "../global/CommonAxios"
import { setAlert } from "./alert"

export const onWhiteLabel = (url, data, loader, setLoader) => (dispatch, getState) => {
    commonAxios(url, data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {

                dispatch(setAlert(res.msg, "success"))
                dispatch(whiteLabelStatus(true))

            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                submit: false
            })
        }).catch((err) => {
            console.log(err)
            setLoader({
                ...loader,
                submit: false
            })
            dispatch(setAlert(err.msg, "danger"))
        })
}

export const onFetchWhiteLabel = (data, setData, loader, setLoader) => (dispatch, getState) => {

    commonAxios("fetch-whitelabel", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {

                setData(res.data[0])
                dispatch(whiteLabelStatus(true))
            }
            setLoader({
                ...loader,
                fetch: false
            })
        }).catch((err) => {
            console.log(err)
            setLoader({
                ...loader,
                fetch: false
            })
            dispatch(setAlert(err.msg, "danger"))
        })
}

export const onCreateMembership = (url, data, fetchMembership, handleClose, loader, setLoader) => (dispatch, getState) => {
    commonAxios(url, { ...data, "user_id": getState().auth.user.id }, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
                fetchMembership()
                handleClose()
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                create: false
            })
        }).catch((err) => {
            console.log(err)
            setLoader({
                ...loader,
                create: false
            })
            dispatch(setAlert(err.msg, "danger"))
        })
}


export const onUpdateWlUserProfile = (data, fetchUser, loader, setLoader) => (dispatch, getState) => {
    commonAxios("change-wl-profile", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
                fetchUser()
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                submitProf: false
            })
        }).catch((err) => {
            console.log(err)
            setLoader({
                ...loader,
                submitProf: false
            })
        })
}

export const onUpdateWlUserPassword = (data, fetchUser, loader, setLoader) => (dispatch, getState) => {
    commonAxios("change-wl-password", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
                fetchUser()
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                submitPass: false
            })
        }).catch((err) => {
            console.log(err)
            setLoader({
                ...loader,
                submitPass: false
            })
        })
}

export const onFetchIsWhitelabel = (data, loader, setLoader) => (dispatch, getState) => {
    commonAxios("fetch-whitelabel-data", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch({ type: 'ADD_REBRAND', payload: res.data[0] })
            }
            setLoader({
                ...loader,
                fetch: false
            })
        }).catch((err) => {
            console.log(err)
            dispatch(setAlert(err.msg, "danger"))
            setLoader({
                ...loader,
                fetch: false
            })
        })
}
// export const onFetchIsWhitelabel = (data, loader, setLoader) => (dispatch, getState) => {
//     commonAxios("fetch-whitelabel-data", data, dispatch, getState().auth.token)
//         .then((res) => {
//             if (res.status) {
//                 let obj = {
//                     reseller: {
//                         ...res.data[0],
//                         app_name: res.data[0].name,
//                     }
//                 }
//                 dispatch({ type: GET_BRANDING_DATA, payload: obj })
//             }
//             setLoader({
//                 ...loader,
//                 fetch: false
//             })
//         }).catch((err) => {
//             console.log(err)
//             dispatch(setAlert(err.msg, "danger"))
//             setLoader({
//                 ...loader,
//                 fetch: false
//             })
//         })
// }

export const fetchStripeAccounts = (setLoader) => (dispatch, getState) => {
    commonAxios("fetch-social-accounts", {}, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch({ type: "ADD_STRIPE_ACCOUNTS", payload: res.data.data.stripe });
            } else {
                dispatch({ type: "ADD_STRIPE_ACCOUNTS", payload: false })
            }
            if (setLoader) {
                setLoader(false)
            }
        }).catch((err) => {
            console.log(err)
            if (setLoader) {
                setLoader(false)
            }
        })
}

export const onConnectInte = (url, data, fetchSocial, handleClose, loader, setLoader) => (dispatch, getState) => {
    commonAxios(url, data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, 'success'))
                fetchSocial()
            } else {
                dispatch(setAlert(res.msg, 'danger'));
            }
            handleClose()
            setLoader({
                ...loader,
                submit: false
            });
        }).catch((err) => {
            setLoader({
                ...loader,
                submit: false
            });
            dispatch(setAlert(err.msg, 'danger'));
            console.log(err)
        })
}

export const deleteStripeAccounts = (data, fetchSocial, setSweet) => (dispatch, getState) => {
    commonAxios("delete-stripe", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, 'success'));
                fetchSocial()
            } else {
                dispatch(setAlert(res.msg, 'danger'));
            }
            setSweet({
                enable: false,
                id: false,
                confirmButtonName: "Disconnect",
                loader: false

            })
        }).catch((err) => {
            console.log(err)
            setSweet({
                enable: false,
                id: false,
                confirmButtonName: "Disconnect",
                loader: false

            })
            dispatch(setAlert(err.message, 'danger'));
        })
}



// No Axios Here

export const whiteLabelStatus = (val) => (dispatch) => {
    dispatch({ type: "WHITELABEL_STATUS_CHANGE", payload: val })
} 