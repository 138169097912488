import React, { useEffect, useState } from "react";
import uploadIcon from "../../images/upload.svg"
import { addRebrandData, removeRebrandData } from "../../actions/rebrandAction";
import { useDispatch, useSelector } from "react-redux";
import { baseURL } from "../../global/global";
import axios from "axios";
import { setAlert } from "../../actions/alert";
import swal from "sweetalert";
import SweetAlert from "react-bootstrap-sweetalert";
import Alert from "../Alert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const UpgradeRebrand = () => {

    const dispatch = useDispatch();

    const auth = useSelector(state => state.auth);
    const rebrandData = useSelector(state => state.rebrand);
    const [rebrand, setRebrand] = useState(rebrandData);
    const [loader, setLoader] = useState(false);

    const [fileData, setFileData] = useState('');
    const [name, setName] = useState('');

    // Sweetalert
    const [button, setButton] = useState('Delete');
    const [deleteData, setDeletedData] = useState({
        id: ''
    });
    const [showDelete, setShowDelete] = useState(false);

    const onConfirm = async () => {
        setButton('Deleting...')
        let options = {
            headers: {
                'Authorization': auth.token
            }
        }

        await axios.post(`${baseURL}delete-wl`, deleteData, options).then(res => {
            if (res.data.status === true) {
                dispatch(setAlert(res.data.message, 'success'));
                dispatch(removeRebrandData(auth.token))
            } else {
                dispatch(setAlert(res.data.message, 'danger'));
            }
            setLoader(false);
        }).catch(err => {
            setLoader(false);
        })

        setShowDelete(false);
        setButton('Delete')
    }

    const onCancel = () => {
        setShowDelete(false);
    }

    const removeWl = (id) => {
        setDeletedData({ ...deleteData, id: id });
        setShowDelete(true);
    }

    const onInputFile = (e) => {
        setFileData(e.target.files[0]);
        dispatch(setAlert("Image uploaded successfully", "success"));

    }

    const onInputName = (e) => {
        setName(e.target.value);
    }

    const onFormSubmit = (e) => {
        e.preventDefault();

        if (fileData.type === 'image/jpg' || fileData.type === 'image/png' || fileData.type === 'image/jpeg' || fileData === 'image/JPEG') {
            if (fileData.size < 5000000) {
                setLoader(true);

                let formData = new FormData();
                formData.append('name', name);
                formData.append('logo', fileData);

                dispatch(addRebrandData(formData, setLoader));

            } else {
                swal("Oops!", "Max Uploaded Size for Image 5MB");
            }
        } else {
            swal("Oops!", "You have Selected Invalid File Type");
        }
    }


    useEffect(() => {
        setRebrand(rebrandData);
    }, [rebrandData])

    return (
        <>
            <Alert />
            <div className="tab-wrapper">
                <div className="tab-header">
                    <p className="fs-18">Agency Rebranding</p>
                </div>
                <form className="tab-desc" method="post" onSubmit={(e) => onFormSubmit(e)}>
                    <div className="ar-upload-wrapper">
                        <p className="fs-18 text-center mb-3">Upload your brand logo (JPG, PNG, JPEG upto 5MB is allowed) <br /> (210x34) Pixels recommended</p>
                        <div className="img-wrapper">
                            <img className="img-fluid mx-auto" src={uploadIcon} alt="" width={34} />
                        </div>
                        <input required type="file" onChange={(e) => onInputFile(e)} accept="image/*" className="upload-brand-logo" name="upload-brand-logo" />
                    </div>
                    <div className="ar-form-wrap">
                        <label className="text-white">Brand Name</label>
                        <input className="text-dark" required type="text" onChange={(e) => onInputName(e)} placeholder="Enter your brand name" />
                        <button disabled={loader} type="submit">{loader ?
                            // <i className="fa fa-spinner fa-spin mr-2"/>
                            <FontAwesomeIcon icon={faSpinner} />
                            : ''} Save</button>
                    </div>
                    {
                        rebrand.data !== false ?
                            <div className="ar-form-wrap mt-4">
                                <button onClick={(e) => removeWl(rebrand.data.id)}>Delete</button>
                            </div>
                            : ''
                    }

                </form>


            </div>

            <SweetAlert
                warning
                showCancel
                confirmBtnText={button}
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="light"
                title="Are you sure?"
                onConfirm={(e) => onConfirm(e)}
                onCancel={(e) => onCancel(e)}
                focusCancelBtn
                show={showDelete}
            >
                Do you want to delete this Rebranding
            </SweetAlert>
        </>
    )
}

export default React.memo(UpgradeRebrand)